.home-container10 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.home-hero-fade-center {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.home-hero {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.8) 48.00%,rgba(255, 255, 255, 0) 74.00%),linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(255, 255, 255, 0) 3.00%),url("/photos/home/638f7ce82168a403dfdb20fc%20cropped.jpg");
  background-position: center;
}
 
.home-content-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.home-max-width1 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  align-self: center;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.home-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.home-prehead1 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-prehead2 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-main-title {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  margin-top: -6px;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000;
}
 
.home-text103 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  padding-top: var(--dl-space-space-halfunit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.home-text109 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  display: none;
  padding-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000;
  padding-bottom: var(--dl-space-space-unit);
}
 
.home-link {
  margin-top: var(--dl-space-space-halfunit);
}
 
.home-bathroom-value {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-quadruple);
  flex-direction: row;
  background-size: cover;
  justify-content: center;
}
 
.home-photo-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.6) 46.00%,rgba(255, 255, 255, 0) 68.00%),url("/photos/home/snapinsta.app_274985307_1167788940695309_6054238171269608400_n_1080-min-1500w.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-max-width2 {
  gap: 48px;
  width: 90%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.home-column1 {
  gap: 24px;
  width: 60%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-heading-container2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text111 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  align-self: center;
  text-align: center;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
}
 
.home-text116 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  max-width: 520px;
  text-align: center;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.home-kitchen-value {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-right: var(--dl-space-space-quadruple);
  flex-direction: row;
  background-size: cover;
  justify-content: center;
}
 
.home-photo-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.6) 44.00%,rgba(255, 255, 255, 0) 69.00%),url("/photos/home/218101624_228229749022861_203455337673028463_n.jpg");
  background-position: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.home-max-width3 {
  gap: 48px;
  width: 90%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.home-column2 {
  gap: 24px;
  width: 60%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-heading-container3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text117 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  align-self: center;
  text-align: center;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
}
 
.home-text121 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  max-width: 520px;
  text-align: center;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.home-remodel-types {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.home-heading-container4 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-prehead3 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000;
}
 
.home-text122 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.home-types {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
 
.home-navlink1 {
  display: contents;
}
 
.home-bathrooms {
  width: 30%;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  aspect-ratio: .75;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.home-image10 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.home-container11 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text126 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-style: normal;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #000000aa;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.home-navlink2 {
  display: contents;
}
 
.home-kitchens {
  width: 30%;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  aspect-ratio: .75;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.home-image11 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.home-container13 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text127 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #000000aa;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.home-navlink3 {
  display: contents;
}
 
.home-renovations {
  width: 30%;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  aspect-ratio: .75;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.home-image12 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.home-container15 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text128 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  text-align: center;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #000000aa;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.home-navlink4 {
  display: contents;
}
 
.home-homes {
  width: 30%;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  aspect-ratio: .75;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.home-image13 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.home-container17 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text132 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  text-align: center;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #000000aa;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.home-navlink5 {
  display: contents;
}
 
.home-ad-us {
  width: 30%;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  aspect-ratio: .75;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.home-image14 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.home-container19 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text136 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  text-align: center;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #000000aa;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-text138 {
  text-transform: lowercase;
}
 
.home-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.home-client-process-bubbles {
  width: 100%;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: -2rem;
  overflow-y: visible;
  padding-top: 2rem;
  margin-bottom: -2rem;
  padding-bottom: 2rem;
  justify-content: center;
}
 
.home-bg {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
}
 
.home-max-width4 {
  gap: 32px;
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  max-width: 1000px;
  align-items: center;
  margin-bottom: var(--dl-space-space-tenunits);
  flex-direction: column;
}
 
.home-heading-container5 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.home-prehead4 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  align-self: center;
  text-align: center;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-text139 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  align-self: center;
  margin-top: -6px;
  text-align: center;
  text-shadow: 0 0 30px #000000;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.home-text140 {
  color: var(--dl-color-gray-700);
  align-self: center;
  text-align: center;
  text-shadow: 0 0 10px #000000;
}
 
.home-text144 {
  color: var(--dl-color-gray-700);
  display: none;
  align-self: center;
  text-align: center;
}
 
.home-timeline-container {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
 
.home-step11 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-container21 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-progress01 {
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-arrow1 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-container22 {
  flex: 0 0 auto;
  width: 15px;
  bottom: 99px;
  height: 3px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-mountainmist);
}
 
.home-text147 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
  right: 10px;
  bottom: 88px;
  display: none;
  position: absolute;
}
 
.home-container23 {
  top: 18px;
  left: 64px;
  width: min(580px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-container24 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-container25 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
 
.home-image15 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-text150 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-text151 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.home-text157 {
  color: var(--dl-color-gray-900);
  font-size: 16px;
}
 
.home-text161 {
  color: var(--dl-color-gray-900);
  font-size: 16px;
}
 
.home-text164 {
  color: var(--dl-color-gray-900);
  font-size: 16px;
}
 
.home-container26 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-image16 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-img31 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/1661766222233-e617a1180fa2.png");
  background-position: top left,bottom right;
}
 
.home-step12 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-container27 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-progress02 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-arrow2 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-container28 {
  top: 18px;
  right: 64px;
  width: min(580px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-container29 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-container30 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-image17 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-text167 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-text168 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.home-text174 {
  color: var(--dl-color-gray-900);
}
 
.home-text177 {
  color: var(--dl-color-gray-900);
}
 
.home-text180 {
  color: var(--dl-color-gray-900);
}
 
.home-container31 {
  top: -25px;
  flex: 0 0 auto;
  left: calc(100% - 1px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-end;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.home-image18 {
  width: 20px;
  object-fit: cover;
  margin-right: -15px;
}
 
.home-container32 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -22px;
  margin: auto;
  display: none;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-image19 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-img32 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/photo-1676760961225-79bca42420ba.png");
  background-position: top left,center;
}
 
.home-step13 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-container33 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-progress03 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-arrow3 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-container34 {
  top: 18px;
  left: 64px;
  width: min(580px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-container35 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-container36 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-image20 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-text183 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-text184 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.home-text190 {
  color: var(--dl-color-gray-900);
}
 
.home-text193 {
  color: var(--dl-color-gray-900);
}
 
.home-text196 {
  color: var(--dl-color-gray-900);
}
 
.home-container37 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-image21 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-img33 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/kaleidico-7lryofj0h9s-unsplash.jpg");
  background-position: top left,center;
}
 
.home-step14 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-container38 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-progress04 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-arrow4 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-container39 {
  top: 18px;
  right: 64px;
  width: min(580px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-container40 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-container41 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-image22 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-text199 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-text200 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.home-text206 {
  color: var(--dl-color-gray-900);
}
 
.home-text209 {
  color: var(--dl-color-gray-900);
}
 
.home-text212 {
  color: var(--dl-color-gray-900);
}
 
.home-text213 {
  color: var(--dl-color-gray-900);
}
 
.home-container42 {
  top: -25px;
  flex: 0 0 auto;
  left: calc(100% - 1px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-end;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.home-image23 {
  width: 20px;
  object-fit: cover;
  margin-right: -15px;
}
 
.home-container43 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -22px;
  margin: auto;
  display: none;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-image24 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-img34 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/pedro-miranda-3qzmbrvceyq-unsplash.jpg");
  background-position: top left,center;
}
 
.home-step15 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-container44 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-progress05 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-arrow5 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-container45 {
  top: 18px;
  left: 64px;
  width: min(580px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-img35 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/snapinsta.app_271881287_134163705739032_1045483567002160642_n_1080-min.jpg");
  background-position: top left,center;
}
 
.home-container46 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-container47 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-image25 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-text216 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-text217 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.home-text224 {
  color: var(--dl-color-gray-900);
}
 
.home-text227 {
  color: var(--dl-color-gray-900);
}
 
.home-text230 {
  color: var(--dl-color-gray-900);
}
 
.home-container48 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-image26 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-step16 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-container49 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-progress06 {
  width: 2px;
  height: 150px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-key-mountainmist);
}
 
.home-container50 {
  flex: 0 0 auto;
  width: 15px;
  bottom: -2px;
  height: 2px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-mountainmist);
}
 
.home-text233 {
  top: 135px;
  fill: var(--dl-color-key-mountainmist);
  left: 10px;
  color: var(--dl-color-key-mountainmist);
  display: none;
  position: absolute;
}
 
.home-container51 {
  top: 18px;
  right: 64px;
  width: min(580px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-container52 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-container53 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-image27 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-text236 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-text237 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.home-text243 {
  color: var(--dl-color-gray-900);
}
 
.home-text246 {
  color: var(--dl-color-gray-900);
}
 
.home-text249 {
  color: var(--dl-color-gray-900);
}
 
.home-container54 {
  top: -25px;
  flex: 0 0 auto;
  left: calc(100% - 1px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-end;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.home-image28 {
  width: 20px;
  object-fit: cover;
  margin-right: -15px;
}
 
.home-container55 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -22px;
  margin: auto;
  display: none;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-image29 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-img36 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/638f7ce82168a403dfdb20fc%20cropped.jpg");
  background-position: top left,center;
}
 
.home-lines1 {
  width: 1920px;
  height: 1080px;
  z-index: 0;
  position: absolute;
  margin-top: -110rem;
  object-fit: cover;
}
 
.home-lines2 {
  width: 1920px;
  height: 1080px;
  z-index: 0;
  position: absolute;
  object-fit: cover;
}
 
.home-meet-the-founder-page-cut {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-shrink: 0;
  margin-bottom: -5rem;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/photos/home/dave-robinson-b2kgtwnnbd4-unsplash%20edited1-1500w.jpg");
  background-position: top;
}
 
.home-gradient-container1 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: none;
  z-index: 1;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(44, 62, 80, 0) 1.00%,rgba(44, 62, 80, 0) 99.00%,rgb(38, 43, 51) 100.00%);
}
 
.home-blur-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(6px) contrast(80%);
}
 
.home-max-width5 {
  display: flex;
  max-width: 1000px;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row-reverse;
  padding-bottom: var(--dl-space-space-fiveunits);
}
 
.home-container56 {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  z-index: 101;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-heading-container6 {
  display: flex;
  align-self: center;
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
 
.home-prehead5 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #fffa, 0 0 30px #fffa;
  margin-bottom: -4px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-text252 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 10px #fffa, 0 0 30px #fffa;
  letter-spacing: -1px;
}
 
.home-text256 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  display: none;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.home-content {
  display: flex;
  align-self: center;
  flex-direction: row;
}
 
.home-line {
  flex: 0 0 auto;
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-style: solid;
  border-width: 2px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 5px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
 
.home-section-title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-text259 {
  text-shadow: 0 0 10px #fffa, 0 0 20px #fff7;
}
 
.home-text262 {
  fill: var(--dl-color-gray-100);
  color: var(--dl-color-gray-100);
  text-shadow: 0 0 10px #fffa, 0 0 20px #fff7;
}
 
.home-image1 {
  bottom: 0px;
  height: 90%;
  z-index: 0;
  position: absolute;
  align-self: center;
  margin-right: 25rem;
}
 
.home-gradient-container2 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: none;
  z-index: 1;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(44, 62, 80, 0) 10.00%,rgba(44, 62, 80, 0) 90.00%,rgb(38, 43, 51) 100.00%);
}
 
.home-text268 {
  display: inline-block;
}
 
.home-text269 {
  display: inline-block;
}
 
.home-text270 {
  display: inline-block;
}
 
.home-text273 {
  display: inline-block;
}
 
.home-text274 {
  display: inline-block;
}
 
.home-text275 {
  display: inline-block;
}
 
.home-text278 {
  display: inline-block;
}
 
.home-text279 {
  display: inline-block;
}
 
.home-text280 {
  display: inline-block;
}
 
.home-text283 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .home-main-title {
    font-size: 3.2rem;
  }
  .home-text103 {
    text-align: left;
  }
  .home-text109 {
    max-width: 520px;
    text-align: left;
  }
  .home-bathroom-value {
    flex-direction: column;
  }
  .home-kitchen-value {
    flex-direction: column;
  }
  .home-text126 {
    font-size: 1.5rem;
  }
  .home-text127 {
    font-size: 1.5rem;
  }
  .home-text128 {
    font-size: 1.5rem;
  }
  .home-text132 {
    font-size: 1.5rem;
  }
  .home-text136 {
    font-size: 1.5rem;
  }
  .home-container21 {
    height: 250px;
  }
  .home-container27 {
    height: 250px;
  }
  .home-container33 {
    height: 250px;
  }
  .home-container38 {
    height: 250px;
  }
  .home-container44 {
    height: 250px;
  }
  .home-container49 {
    height: 250px;
  }
  .home-image1 {
    margin-right: 35%;
  }
}
 
@media(max-width: 767px) {
  .home-hero {
    justify-content: center;
  }
  .home-max-width1 {
    align-items: center;
  }
  .home-main-title {
    font-size: 42px;
  }
  .home-text103 {
    display: none;
    text-align: center;
  }
  .home-text109 {
    fill: var(--dl-color-gray-800);
    color: var(--dl-color-gray-800);
    display: block;
  }
  .home-photo-container1 {
    height: 400px;
  }
  .home-column1 {
    width: 80%;
  }
  .home-heading-container2 {
    align-items: center;
  }
  .home-text111 {
    font-size: 32px;
  }
  .home-text116 {
    font-size: 14px;
  }
  .home-photo-container2 {
    height: 400px;
  }
  .home-column2 {
    width: 80%;
  }
  .home-heading-container3 {
    align-items: center;
  }
  .home-text117 {
    font-size: 32px;
  }
  .home-text121 {
    font-size: 14px;
  }
  .home-heading-container4 {
    align-items: center;
  }
  .home-prehead3 {
    text-align: center;
  }
  .home-types {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
  .home-bathrooms {
    width: 45%;
  }
  .home-text126 {
    font-size: 1.7rem;
  }
  .home-kitchens {
    width: 45%;
  }
  .home-text127 {
    font-size: 1.7rem;
  }
  .home-renovations {
    width: 45%;
  }
  .home-text128 {
    font-size: 1.7rem;
  }
  .home-homes {
    width: 45%;
  }
  .home-text132 {
    font-size: 1.7rem;
  }
  .home-ad-us {
    width: 45%;
  }
  .home-text136 {
    font-size: 1.7rem;
  }
  .home-heading-container5 {
    align-items: center;
  }
  .home-prehead4 {
    text-align: center;
  }
  .home-text139 {
    font-size: 32px;
  }
  .home-text140 {
    display: none;
  }
  .home-text144 {
    color: var(--dl-color-gray-700);
    display: block;
  }
  .home-timeline-container {
    margin-right: var(--dl-space-space-tripleunit);
  }
  .home-step11 {
    align-items: flex-end;
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-container21 {
    height: 100%;
  }
  .home-progress01 {
    height: 300px;
  }
  .home-container22 {
    bottom: 299px;
  }
  .home-text147 {
    bottom: 288px;
  }
  .home-container23 {
    top: 0;
    left: 40px;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text150 {
    text-align: left;
  }
  .home-step12 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-container27 {
    height: 100%;
  }
  .home-container28 {
    top: 0;
    left: 40px;
    right: auto;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text167 {
    text-align: left;
  }
  .home-container31 {
    display: none;
  }
  .home-container32 {
    display: flex;
  }
  .home-step13 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-container33 {
    height: 100%;
  }
  .home-container34 {
    top: 0;
    left: 40px;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text183 {
    text-align: left;
  }
  .home-step14 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-container38 {
    height: 100%;
  }
  .home-container39 {
    top: 0;
    left: 40px;
    right: auto;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text199 {
    text-align: left;
  }
  .home-container42 {
    display: none;
  }
  .home-container43 {
    display: flex;
  }
  .home-step15 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-container44 {
    height: 100%;
  }
  .home-container45 {
    top: 0;
    left: 40px;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text216 {
    text-align: left;
  }
  .home-step16 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-container49 {
    height: 100%;
  }
  .home-progress06 {
    height: 200px;
  }
  .home-text233 {
    top: 185px;
    left: auto;
    right: 10px;
  }
  .home-container51 {
    top: 0;
    left: 40px;
    right: auto;
    width: 70vw;
    position: relative;
  }
  .home-text236 {
    text-align: left;
  }
  .home-container54 {
    display: none;
  }
  .home-container55 {
    display: flex;
  }
  .home-max-width5 {
    gap: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .home-container56 {
    width: 100%;
    margin-bottom: 75%;
  }
  .home-heading-container6 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-prehead5 {
    text-align: center;
  }
  .home-content {
    width: 80%;
  }
  .home-image1 {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
}
 
@media(max-width: 479px) {
  .home-max-width2 {
    gap: 24px;
  }
  .home-column1 {
    width: 100%;
  }
  .home-text111 {
    font-size: 26px;
  }
  .home-column2 {
    width: 100%;
  }
  .home-text117 {
    font-size: 26px;
  }
  .home-text122 {
    font-size: 2rem;
  }
  .home-types {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-bathrooms {
    margin-left: var(--dl-space-space-quarterunit);
    margin-right: var(--dl-space-space-quarterunit);
  }
  .home-text126 {
    font-size: 1rem;
  }
  .home-kitchens {
    margin-left: var(--dl-space-space-quarterunit);
    margin-right: var(--dl-space-space-quarterunit);
  }
  .home-text127 {
    font-size: 1rem;
  }
  .home-renovations {
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-quarterunit);
    margin-right: var(--dl-space-space-quarterunit);
  }
  .home-text128 {
    font-size: 1rem;
  }
  .home-homes {
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-quarterunit);
    margin-right: var(--dl-space-space-quarterunit);
  }
  .home-text132 {
    font-size: 1rem;
  }
  .home-ad-us {
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-quarterunit);
    margin-right: var(--dl-space-space-quarterunit);
  }
  .home-text136 {
    font-size: 1rem;
  }
  .home-step11 {
    margin-left: 0;
  }
  .home-text147 {
    display: none;
  }
  .home-step12 {
    margin-left: 0;
  }
  .home-step13 {
    margin-left: 0;
  }
  .home-step14 {
    margin-left: 0;
  }
  .home-step15 {
    margin-left: 0;
  }
  .home-step16 {
    margin-left: 0;
  }
  .home-text233 {
    display: none;
  }
  .home-max-width5 {
    gap: 24px;
  }
  .home-text252 {
    display: none;
  }
  .home-text256 {
    display: block;
    font-size: 2rem;
  }
  .home-text271 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-text281 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
