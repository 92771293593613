.renovations-additions-container1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.renovations-additions-hero-fade-single {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
  justify-content: center;
}
 
.renovations-additions-hero {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.8) 38.00%,rgba(38, 43, 51, 0.5) 50.00%,rgba(38, 43, 51, 0) 60.00%),url("/photos/remodels_additions/img_01471-1500w.jpg");
  background-position: center;
}
 
.renovations-additions-content-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
 
.renovations-additions-max-width1 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.renovations-additions-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.renovations-additions-prehead1 {
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000, 0 0 30px #000000, 0 0 4px #000000;
}
 
.renovations-additions-main-title {
  text-align: left;
}
 
.renovations-additions-text14 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  max-width: 500px;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.renovations-additions-showcase {
  gap: var(--dl-space-space-tripleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.renovations-additions-heading {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.renovations-additions-prehead2 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.renovations-additions-text15 {
  max-width: 800px;
  text-align: center;
}
 
.renovations-additions-row1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.renovations-additions-image10 {
  flex: 0 0 auto;
  width: 100%;
  height: 400px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.8) 40.00%,rgba(255, 255, 255, 0) 63.00%),url("/photos/remodels_additions/img_0548-1500w.jpeg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.renovations-additions-container2 {
  top: 0px;
  left: var(--dl-space-space-doubleunit);
  bottom: 0px;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: absolute;
  max-width: 390px;
  align-items: flex-start;
  flex-direction: row;
}
 
.renovations-additions-text16 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.renovations-additions-text17 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.renovations-additions-bio1 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.renovations-additions-text19 {
  color: var(--dl-color-key-iron);
}
 
.renovations-additions-row2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.renovations-additions-image11 {
  flex: 0 0 auto;
  width: 100%;
  height: 400px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.8) 38.00%,rgba(255, 255, 255, 0) 63.00%),url("/photos/remodels_additions/image1%20(1)-min-1500w.jpeg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.renovations-additions-container3 {
  top: 0px;
  right: var(--dl-space-space-doubleunit);
  bottom: 0px;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: absolute;
  max-width: 390px;
  align-items: flex-start;
  flex-direction: row;
}
 
.renovations-additions-text20 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.renovations-additions-text21 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.renovations-additions-bio2 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.renovations-additions-text23 {
  color: var(--dl-color-key-iron);
}
 
.renovations-additions-row3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.renovations-additions-image12 {
  flex: 0 0 auto;
  width: 100%;
  height: 400px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.8) 38.00%,rgba(255, 255, 255, 0) 63.00%),url("/photos/remodels_additions/337428342_553691676601498_7416457620280275304_n1-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.renovations-additions-container4 {
  left: var(--dl-space-space-doubleunit);
  bottom: 91px;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: absolute;
  max-width: 390px;
  align-items: flex-start;
  flex-direction: row;
}
 
.renovations-additions-text24 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.renovations-additions-text25 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.renovations-additions-bio3 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.renovations-additions-text27 {
  color: var(--dl-color-key-iron);
}
 
.renovations-additions-material-grid {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.renovations-additions-heading-container2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.renovations-additions-text28 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  letter-spacing: 1px;
}
 
.renovations-additions-type-container {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  flex-direction: row;
  justify-content: center;
}
 
.renovations-additions-collumn {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.renovations-additions-type1 {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
 
.renovations-additions-image13 {
  width: 48%;
  height: 500px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.renovations-additions-text33 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.renovations-additions-text34 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 10px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.renovations-additions-bio4 {
  color: var(--dl-color-key-mountainmist);
}
 
.renovations-additions-type2 {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  margin-top: -125px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
 
.renovations-additions-text37 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.renovations-additions-text38 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 10px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.renovations-additions-bio5 {
  color: var(--dl-color-key-mountainmist);
}
 
.renovations-additions-image14 {
  width: 48%;
  height: 500px;
  object-fit: cover;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.renovations-additions-type3 {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  margin-top: -125px;
  align-items: flex-start;
  flex-direction: row;
}
 
.renovations-additions-image15 {
  width: 48%;
  height: 500px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.renovations-additions-text39 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.renovations-additions-text40 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 10px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.renovations-additions-bio6 {
  color: var(--dl-color-key-mountainmist);
}
 
.renovations-additions-type4 {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  margin-top: -125px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
 
.renovations-additions-text43 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.renovations-additions-text44 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 10px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.renovations-additions-bio7 {
  color: var(--dl-color-key-mountainmist);
}
 
.renovations-additions-image16 {
  width: 48%;
  height: 500px;
  object-fit: cover;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.renovations-additions-type5 {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  margin-top: -125px;
  align-items: flex-start;
  flex-direction: row;
}
 
.renovations-additions-image17 {
  width: 48%;
  height: 500px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.renovations-additions-text45 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.renovations-additions-text46 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 10px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.renovations-additions-bio8 {
  color: var(--dl-color-key-mountainmist);
}
 
.renovations-additions-integration {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.renovations-additions-max-width2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
 
.renovations-additions-image18 {
  flex: 0 0 auto;
  width: 95%;
  height: 600px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.8) 55.00%,rgba(255, 255, 255, 0) 79.00%),url("/photos/remodels_additions/135259847_880543086036237_2352218503890894970_n%20cropped-1500w.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.renovations-additions-float {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 500px;
  margin: auto;
  display: flex;
  position: absolute;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
}
 
.renovations-additions-content1 {
  flex: 0 0 auto;
  width: 600px;
  height: 400px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: column;
}
 
.renovations-additions-prehead4 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.renovations-additions-text49 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.renovations-additions-text50 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.renovations-additions-text52 {
  color: var(--dl-color-key-iron);
}
 
.renovations-additions-text54 {
  color: var(--dl-color-key-iron);
}
 
.renovations-additions-text58 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.renovations-additions-text60 {
  color: var(--dl-color-key-iron);
}
 
.renovations-additions-cta {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.renovations-additions-max-width3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.renovations-additions-image19 {
  flex: 0 0 auto;
  width: 100%;
  height: 400px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.8) 2.00%,rgba(38, 43, 51, 0.8) 97.00%,rgb(38, 43, 51) 100.00%),url("/photos/remodels_additions/image0%20cropped-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.renovations-additions-content2 {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: absolute;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
 
.renovations-additions-content3 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.renovations-additions-text62 {
  text-align: center;
}
 
.renovations-additions-text63 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  width: 550px;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
 
.renovations-additions-text65 {
  display: inline-block;
}
 
.renovations-additions-text66 {
  display: inline-block;
}
 
.renovations-additions-text67 {
  display: inline-block;
}
 
.renovations-additions-text70 {
  display: inline-block;
}
 
.renovations-additions-text71 {
  display: inline-block;
}
 
.renovations-additions-text72 {
  display: inline-block;
}
 
.renovations-additions-text75 {
  display: inline-block;
}
 
.renovations-additions-text76 {
  display: inline-block;
}
 
.renovations-additions-text77 {
  display: inline-block;
}
 
.renovations-additions-text80 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .renovations-additions-type-container {
    gap: 0;
    flex-direction: column;
  }
  .renovations-additions-text33 {
    width: 350px;
  }
  .renovations-additions-text37 {
    width: 350px;
  }
  .renovations-additions-text39 {
    width: 350px;
  }
  .renovations-additions-text43 {
    width: 350px;
  }
  .renovations-additions-text45 {
    width: 350px;
  }
  .renovations-additions-float {
    top: 0px;
    bottom: 0px;
    height: auto;
    margin: auto;
    justify-content: center;
  }
  .renovations-additions-content1 {
    width: 500px;
    height: auto;
    padding: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 767px) {
  .renovations-additions-hero {
    justify-content: center;
  }
  .renovations-additions-max-width1 {
    align-items: center;
  }
  .renovations-additions-row1 {
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .renovations-additions-image10 {
    width: 90%;
  }
  .renovations-additions-container2 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
  }
  .renovations-additions-text16 {
    width: 100%;
  }
  .renovations-additions-row2 {
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .renovations-additions-image11 {
    width: 90%;
    align-self: flex-end;
  }
  .renovations-additions-container3 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
    align-self: flex-end;
  }
  .renovations-additions-text20 {
    width: 100%;
  }
  .renovations-additions-row3 {
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .renovations-additions-image12 {
    width: 90%;
  }
  .renovations-additions-container4 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
  }
  .renovations-additions-text24 {
    width: 100%;
  }
  .renovations-additions-material-grid {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .renovations-additions-heading-container2 {
    align-items: center;
  }
  .renovations-additions-prehead3 {
    text-align: center;
  }
  .renovations-additions-type-container {
    padding: 0;
  }
  .renovations-additions-text33 {
    width: 230px;
  }
  .renovations-additions-type2 {
    margin-top: -50px;
  }
  .renovations-additions-text37 {
    width: 230px;
    margin-top: 50px;
  }
  .renovations-additions-type3 {
    margin-top: -50px;
  }
  .renovations-additions-text39 {
    width: 230px;
  }
  .renovations-additions-type4 {
    margin-top: -50px;
  }
  .renovations-additions-text43 {
    width: 230px;
    margin-top: 50px;
  }
  .renovations-additions-type5 {
    margin-top: -50px;
  }
  .renovations-additions-text45 {
    width: 230px;
  }
  .renovations-additions-max-width2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .renovations-additions-image18 {
    width: 100%;
    height: 400px;
    background-image: url("/photos/remodels_additions/135259847_880543086036237_2352218503890894970_n%20cropped-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-top-right-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .renovations-additions-float {
    width: 100%;
    height: auto;
    position: relative;
    margin-top: -10rem;
  }
  .renovations-additions-content1 {
    width: 100%;
    height: auto;
    padding: var(--dl-space-space-unit);
  }
  .renovations-additions-max-width3 {
    flex-direction: column;
  }
  .renovations-additions-content3 {
    padding: var(--dl-space-space-unit);
  }
  .renovations-additions-text63 {
    width: 100%;
  }
}
 
@media(max-width: 479px) {
  .renovations-additions-content-container {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .renovations-additions-main-title {
    font-size: 2.2rem;
  }
  .renovations-additions-text15 {
    font-size: 2rem;
  }
  .renovations-additions-text16 {
    margin-bottom: 0px;
  }
  .renovations-additions-text20 {
    margin-bottom: 0px;
  }
  .renovations-additions-text24 {
    margin-bottom: 0px;
  }
  .renovations-additions-material-grid {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .renovations-additions-type1 {
    align-items: center;
    flex-direction: column;
  }
  .renovations-additions-image13 {
    width: 300px;
  }
  .renovations-additions-text33 {
    width: 300px;
    margin-bottom: 0px;
  }
  .renovations-additions-type2 {
    margin-top: 0px;
    align-items: center;
    flex-direction: column-reverse;
  }
  .renovations-additions-text37 {
    width: 300px;
    margin-top: 0px;
  }
  .renovations-additions-image14 {
    width: 300px;
  }
  .renovations-additions-type3 {
    margin-top: 0;
    align-items: center;
    flex-direction: column;
  }
  .renovations-additions-image15 {
    width: 300px;
  }
  .renovations-additions-text39 {
    width: 300px;
    margin-bottom: 0px;
  }
  .renovations-additions-type4 {
    margin-top: 0px;
    align-items: center;
    flex-direction: column-reverse;
  }
  .renovations-additions-text43 {
    width: 300px;
    margin-top: 0px;
  }
  .renovations-additions-image16 {
    width: 300px;
  }
  .renovations-additions-type5 {
    margin-top: 0;
    align-items: center;
    flex-direction: column;
  }
  .renovations-additions-image17 {
    width: 300px;
  }
  .renovations-additions-text45 {
    width: 300px;
    margin-bottom: 0px;
  }
  .renovations-additions-image18 {
    height: 250px;
  }
  .renovations-additions-float {
    margin-top: -5rem;
  }
  .renovations-additions-text62 {
    font-size: 2rem;
  }
  .renovations-additions-text68 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .renovations-additions-text78 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
