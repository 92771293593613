.navbar-navbar {
  width: 100%;
  display: flex;
  z-index: 101;
  position: fixed;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(38, 43, 51, 0.9);
}
 
.navbar-container10 {
  width: 100%;
  display: flex;
  z-index: 102;
  position: relative;
  max-width: 1000px;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
 
.navbar-navlink10 {
  display: contents;
}
 
.navbar-left {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  text-decoration: none;
}
 
.navbar-image10 {
  height: 150px;
  opacity: 0.35;
  margin-top: -50px;
  object-fit: cover;
  margin-right: -200px;
  margin-bottom: -50px;
}
 
.navbar-image11 {
  width: 200px;
  z-index: 100;
  object-fit: cover;
}
 
.navbar-right {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.navbar-links-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.navbar-navlink11 {
  margin-top: var(--dl-space-space-quarterunit);
}
 
.navbar-navlink12 {
  margin-top: var(--dl-space-space-quarterunit);
}
 
.navbar-services1 {
  height: 92px;
  display: flex;
  position: relative;
  align-items: center;
  margin-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
 
.navbar-text10 {
  margin-top: var(--dl-space-space-quarterunit);
  margin-right: 0px;
}
 
.navbar-image12 {
  top: var(--dl-space-space-quarterunit);
  right: 0px;
  bottom: 0px;
  height: 30px;
  margin: auto;
  position: absolute;
  align-self: center;
  object-fit: cover;
}
 
.navbar-image13 {
  height: 30px;
  z-index: 100;
  align-self: center;
  margin-top: var(--dl-space-space-quarterunit);
  object-fit: cover;
}

/* @keyframes fadeUp {
  from {
    margin-top: -10px;
    opacity: 0%;
  }
  to{
    margin-top: 0;
    opacity: 100%;
  }
} */
 
.navbar-text11 {
  font-family: "Goldman";
  text-transform: uppercase;
}
 
.navbar-services-menu {
  top: 100%;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 200px;
  margin: auto;
  display: flex;
  position: absolute;
  max-width: 1000px;
  flex-direction: row-reverse;
  /* animation: fadeUp .4s ease-out; */
}
 
.navbar-bg1x4 {
  gap: var(--dl-space-space-quarterunit);
  flex: 0 0 auto;
  width: 400px;
  height: 600px;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: flex-start;
  border-color: var(--dl-color-key-gunmetal);
  border-width: 1px;
  margin-right: 12rem;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  backdrop-filter: blur(6px);
  background-color: var(--dl-color-key-gunmetal);
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
 
.navbar-navlink14 {
  display: contents;
}
 
.navbar-bathrooms1 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  aspect-ratio: .75;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.navbar-image14 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.navbar-container11 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar-text12 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.7rem;
  font-style: normal;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000aa;
  margin-right: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.navbar-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.navbar-navlink15 {
  display: contents;
}
 
.navbar-kitchens1 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  aspect-ratio: .75;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.navbar-image15 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.navbar-container13 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar-text13 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.7rem;
  font-family: "Goldman";
  font-weight: 500;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000aa;
  margin-right: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.navbar-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.navbar-navlink16 {
  display: contents;
}
 
.navbar-renovationsadditions1 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  aspect-ratio: .75;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.navbar-image16 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.navbar-container15 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar-text14 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.7rem;
  text-align: center;
  font-family: "Goldman";
  font-weight: 500;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000aa;
  margin-right: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.navbar-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.navbar-navlink17 {
  display: contents;
}
 
.navbar-customhomes1 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  aspect-ratio: .75;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.navbar-image17 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.navbar-container17 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar-text18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.7rem;
  text-align: center;
  font-family: "Goldman";
  font-weight: 500;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000aa;
  margin-right: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.navbar-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.navbar-navlink18 {
  display: contents;
}
 
.navbar-customhomes2 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  aspect-ratio: .75;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.navbar-image18 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.navbar-container19 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar-text22 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.7rem;
  text-align: center;
  font-family: "Goldman";
  font-weight: 500;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000aa;
  margin-right: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.navbar-text24 {
  text-transform: lowercase;
}
 
.navbar-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.navbar-bg2x2 {
  gap: var(--dl-space-space-quarterunit);
  flex: 0 0 auto;
  width: 500px;
  height: 300px;
  display: none;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: flex-start;
  border-color: var(--dl-color-key-gunmetal);
  border-width: 1px;
  margin-right: 12rem;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: row;
  backdrop-filter: blur(6px);
  background-color: var(--dl-color-key-gunmetal);
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
 
.navbar-bathrooms2 {
  width: calc(50% - .125rem);
  height: 50%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  aspect-ratio: .75;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.navbar-image19 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.navbar-container21 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar-text25 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.7rem;
  font-style: normal;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000aa;
  margin-right: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.navbar-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.navbar-kitchens2 {
  width: calc(50% - .125rem);
  height: 50%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  aspect-ratio: .75;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.navbar-image20 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.navbar-container23 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar-text26 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.7rem;
  font-family: "Goldman";
  font-weight: 500;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000aa;
  margin-right: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.navbar-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.navbar-renovationsadditions2 {
  width: calc(50% - .125rem);
  height: 50%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  aspect-ratio: .75;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.navbar-image21 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.navbar-container25 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar-text27 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.7rem;
  text-align: center;
  font-family: "Goldman";
  font-weight: 500;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000aa;
  margin-right: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.navbar-container26 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.navbar-customhomes3 {
  width: calc(50% - .125rem);
  height: 50%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  aspect-ratio: .75;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
 
.navbar-image22 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.navbar-container27 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar-text31 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.7rem;
  text-align: center;
  font-family: "Goldman";
  font-weight: 500;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000aa;
  margin-right: 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.navbar-container28 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.navbar-burger-menu1 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.navbar-burger-menu2 {
  fill: var(--dl-color-key-iron);
  width: 30px;
  height: 30px;
}
 
.navbar-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}
 
.navbar-nav {
  top: 100%;
  flex: 0 0 auto;
  left: 0;
  width: 100vw;
  display: flex;
  position: absolute;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  backdrop-filter: blur(6px);
  background-color: rgba(38, 43, 51, 0.9);
}
 
.navbar-right-side {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: DoubleUnit;
  flex-direction: column;
  justify-content: space-between;
}
 
.navbar-links-container2 {
  display: flex;
  margin-top: 16px;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;
}
 
.navbar-navlink24 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  line-height: 1.55;
  text-decoration: none;
}
 
.navbar-navlink25 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  line-height: 1.55;
  text-decoration: none;
}
 
.navbar-navlink26 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  line-height: 1.55;
  text-decoration: none;
}
 
.navbar-services2 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar-head {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
  font-size: 1rem;
  margin-bottom: -6px;
  text-transform: capitalize;
}
 
.navbar-services-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar-navlink27 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  line-height: 1.55;
  text-decoration: none;
}
 
.navbar-navlink28 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  line-height: 1.55;
  text-decoration: none;
}
 
.navbar-navlink29 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  line-height: 1.55;
  text-decoration: none;
}
 
.navbar-navlink30 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  line-height: 1.55;
}
 
.navbar-navlink31 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  font-size: 1.3rem;
  font-family: "Goldman";
  font-weight: 500;
  line-height: 1.55;
  letter-spacing: 1px;
  text-decoration: none;
}
 
.navbar-text37 {
  text-transform: lowercase;
}
 
.navbar-blur {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  backdrop-filter: blur(6px);
}
 

 

 
@media(max-width: 991px) {
  .navbar-container10 {
    padding-left: 0px;
  }
  .navbar-services1 {
    margin-right: var(--dl-space-space-unit);
  }
  .navbar-services-container {
    margin-left: var(--dl-space-space-halfunit);
  }
}
 
@media(max-width: 767px) {
  .navbar-container10 {
    padding-right: 1em;
  }
  .navbar-left {
    position: relative;
  }
  .navbar-right {
    display: none;
  }
  .navbar-burger-menu1 {
    display: flex;
  }
  .navbar-navlink23 {
    margin-right: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 479px) {
  .navbar-navlink23 {
    display: none;
  }
}
