.ad-us-container1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.ad-us-hero-fade-single {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
  justify-content: center;
}
 
.ad-us-hero {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.8) 26.00%,rgba(38, 43, 51, 0.5) 42.00%,rgba(38, 43, 51, 0) 54.00%),url("/photos/adus/adobestock_959276376.jpeg");
  background-position: center;
}
 
.ad-us-content-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.ad-us-max-width1 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.ad-us-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.ad-us-prehead1 {
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000, 0 0 30px #000000, 0 0 4px #000000;
}
 
.ad-us-text11 {
  text-transform: lowercase;
}
 
.ad-us-main-title {
  text-align: left;
}
 
.ad-us-text15 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  max-width: 500px;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-guide {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.ad-us-max-width2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
 
.ad-us-image1 {
  flex: 0 0 auto;
  width: 95%;
  height: 600px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.8) 55.00%,rgba(255, 255, 255, 0) 79.00%),url("/photos/adus/adobestock_1028132889-1500w.jpeg");
  background-position: top left,left;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.ad-us-float {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 500px;
  margin: auto;
  display: flex;
  position: absolute;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
}
 
.ad-us-content1 {
  flex: 0 0 auto;
  width: 600px;
  height: 400px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: column;
}
 
.ad-us-prehead2 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.ad-us-text16 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 2.8rem;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: uppercase;
  text-decoration: none;
}
 
.ad-us-text18 {
  text-transform: lowercase;
}
 
.ad-us-text19 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-text21 {
  color: var(--dl-color-gray-900);
}
 
.ad-us-text23 {
  color: var(--dl-color-gray-900);
}
 
.ad-us-text25 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-bullet-point1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: row;
}
 
.ad-us-text28 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-900);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-text31 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-text32 {
  color: var(--dl-color-gray-900);
}
 
.ad-us-bullet-point2 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: row;
}
 
.ad-us-text35 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-text38 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-text39 {
  color: var(--dl-color-gray-900);
}
 
.ad-us-bullet-point3 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: row;
}
 
.ad-us-text42 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-text45 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-text46 {
  color: var(--dl-color-gray-900);
}
 
.ad-us-types {
  width: 100%;
  display: flex;
  overflow: visible;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
 
.ad-us-heading-container2 {
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.ad-us-prehead3 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000;
  margin-bottom: -6px;
}
 
.ad-us-text49 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-align: center;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
}
 
.ad-us-value-container {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1250px;
  flex-direction: row;
  justify-content: center;
}
 
.ad-us-container2 {
  flex: 1;
  display: none;
  box-shadow: 0px 10px 20px 0px #000000;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 100.00%),url("/photos/adus/clay-banks-g4oqpcax6lw-unsplash-1300w.jpg");
  background-position: top left,center;
}
 
.ad-us-head1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.ad-us-icon1 {
  width: 100px;
  object-fit: cover;
}
 
.ad-us-text50 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  z-index: 100;
  font-size: 1.6rem;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  margin-bottom: var(--dl-space-space-halfunit);
  letter-spacing: -1px;
  text-transform: capitalize;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
 
.ad-us-text51 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-container3 {
  width: 400px;
  display: flex;
  box-shadow: 0px 10px 20px 0px #000000;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 1.00%,rgba(38, 43, 51, 0.9) 100.00%),url("/photos/adus/nachelle-nocom-51adhgg5kke-unsplash-1300w.jpg");
  background-repeat: auto,cover;
  background-position: center;
}
 
.ad-us-head2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.ad-us-icon2 {
  width: 100px;
  object-fit: cover;
}
 
.ad-us-text52 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  z-index: 100;
  font-size: 1.6rem;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  margin-bottom: var(--dl-space-space-halfunit);
  letter-spacing: -1px;
  text-transform: capitalize;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
 
.ad-us-text53 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-container4 {
  width: 400px;
  display: flex;
  box-shadow: 0px 10px 20px 0px #000000;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 1.00%,rgba(38, 43, 51, 0.9) 100.00%),url("/photos/adus/andrea-davis-nbi8gqbbaho-unsplash-1300w.jpg");
  background-position: top left,center;
}
 
.ad-us-head3 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.ad-us-icon3 {
  width: 100px;
  object-fit: cover;
}
 
.ad-us-text54 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  z-index: 100;
  font-size: 1.6rem;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  margin-bottom: var(--dl-space-space-halfunit);
  letter-spacing: -1px;
  text-transform: capitalize;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
 
.ad-us-text55 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.ad-us-material-grid {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.ad-us-heading-container3 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.ad-us-text56 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  letter-spacing: 1px;
}
 
.ad-us-type-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1200px;
  aspect-ratio: 1;
  flex-direction: column;
  justify-content: space-between;
}
 
.ad-us-row1 {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  height: 31%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row-reverse;
}
 
.ad-us-image2 {
  height: 100%;
  min-width: 0px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.ad-us-image3 {
  height: 100%;
  min-width: 0px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.ad-us-row2 {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  height: 31%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
}
 
.ad-us-image4 {
  height: 100%;
  min-width: 0px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.ad-us-image5 {
  height: 100%;
  min-width: 0px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.ad-us-row3 {
  gap: var(--dl-space-space-doubleunit);
  width: 100%;
  height: 31%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
}
 
.ad-us-image6 {
  height: 100%;
  min-width: 0px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.ad-us-image7 {
  height: 100%;
  min-width: 0px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.ad-us-cta {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.ad-us-max-width3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.ad-us-image8 {
  flex: 0 0 auto;
  width: 100%;
  height: 600px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.8) 10.00%,rgba(38, 43, 51, 0.8) 90.00%,rgb(38, 43, 51) 100.00%),url("/photos/adus/clay-banks-itg6wgoajay-unsplash.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.ad-us-content2 {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: absolute;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
 
.ad-us-content3 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.ad-us-text59 {
  text-align: center;
}
 
.ad-us-text60 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  width: 550px;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
 
.ad-us-text65 {
  display: inline-block;
}
 
.ad-us-text66 {
  display: inline-block;
}
 
.ad-us-text67 {
  display: inline-block;
}
 
.ad-us-text70 {
  display: inline-block;
}
 
.ad-us-text71 {
  display: inline-block;
}
 
.ad-us-text72 {
  display: inline-block;
}
 
.ad-us-text75 {
  display: inline-block;
}
 
.ad-us-text76 {
  display: inline-block;
}
 
.ad-us-text77 {
  display: inline-block;
}
 
.ad-us-text80 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .ad-us-float {
    top: 0px;
    bottom: 0px;
    height: auto;
    margin: auto;
    justify-content: center;
  }
  .ad-us-content1 {
    width: 500px;
    height: auto;
    padding: var(--dl-space-space-unit);
  }
  .ad-us-type-container {
    gap: 0;
    flex-direction: column;
  }
}
 
@media(max-width: 767px) {
  .ad-us-hero {
    justify-content: center;
  }
  .ad-us-max-width1 {
    align-items: center;
  }
  .ad-us-max-width2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .ad-us-image1 {
    width: 100%;
    height: 400px;
    background-image: url("/photos/adus/adobestock_1028132889-1500w.jpeg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-top-right-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ad-us-float {
    width: 100%;
    height: auto;
    position: relative;
    margin-top: -10rem;
  }
  .ad-us-content1 {
    width: 100%;
    height: auto;
    padding: var(--dl-space-space-unit);
  }
  .ad-us-types {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .ad-us-heading-container2 {
    align-items: center;
  }
  .ad-us-prehead3 {
    text-align: center;
  }
  .ad-us-container2 {
    flex: 0 0 auto;
    width: 75%;
  }
  .ad-us-container3 {
    width: 75%;
  }
  .ad-us-container4 {
    width: 75%;
  }
  .ad-us-material-grid {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .ad-us-heading-container3 {
    align-items: center;
  }
  .ad-us-prehead4 {
    text-align: center;
  }
  .ad-us-type-container {
    padding: 0;
  }
  .ad-us-row1 {
    gap: var(--dl-space-space-unit);
  }
  .ad-us-row2 {
    gap: var(--dl-space-space-unit);
  }
  .ad-us-row3 {
    gap: var(--dl-space-space-unit);
  }
  .ad-us-max-width3 {
    flex-direction: column;
  }
  .ad-us-content3 {
    padding: var(--dl-space-space-unit);
  }
  .ad-us-text60 {
    width: 100%;
  }
}
 
@media(max-width: 479px) {
  .ad-us-image1 {
    height: 250px;
    background-image: url("/photos/adus/adobestock_1028132889-1500w.jpeg");
  }
  .ad-us-float {
    margin-top: -5rem;
  }
  .ad-us-text49 {
    font-size: 2rem;
  }
  .ad-us-container2 {
    width: 100%;
  }
  .ad-us-icon1 {
    width: 75px;
  }
  .ad-us-container3 {
    width: 100%;
  }
  .ad-us-icon2 {
    width: 75px;
  }
  .ad-us-container4 {
    width: 100%;
  }
  .ad-us-icon3 {
    width: 75px;
  }
  .ad-us-material-grid {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .ad-us-row1 {
    gap: var(--dl-space-space-halfunit);
  }
  .ad-us-image2 {
    width: 300px;
  }
  .ad-us-image3 {
    width: 300px;
  }
  .ad-us-row2 {
    gap: var(--dl-space-space-halfunit);
  }
  .ad-us-image4 {
    width: 300px;
  }
  .ad-us-image5 {
    width: 300px;
  }
  .ad-us-row3 {
    gap: var(--dl-space-space-halfunit);
  }
  .ad-us-image6 {
    width: 300px;
  }
  .ad-us-image7 {
    width: 300px;
  }
  .ad-us-text59 {
    font-size: 2rem;
  }
  .ad-us-text68 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .ad-us-text78 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
