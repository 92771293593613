.pallette-container10 {
  gap: var(--dl-space-space-tenunits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-divider1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-quadruple);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-hero-fade1 {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-hero1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 7.00%,rgba(255, 255, 255, 0) 41.00%),url("/photos/home/638f7ce82168a403dfdb20fc%20cropped.jpg");
  background-position: center;
}
 
.pallette-content-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-max-width10 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-heading-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.pallette-prehead10 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-prehead11 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  display: none;
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-main-title1 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  margin-top: -6px;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000;
}
 
.pallette-text104 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  padding-top: var(--dl-space-space-halfunit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-text110 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  display: none;
  padding-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
  padding-bottom: var(--dl-space-space-unit);
}
 
.pallette-link1 {
  margin-top: var(--dl-space-space-halfunit);
}
 
.pallette-banner {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-hero2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.9) 7.00%,rgba(38, 43, 51, 0.9) 100.00%),url("/photos/home/process/snapinsta.app_271881287_134163705739032_1045483567002160642_n_1080-min.jpg");
  background-position: top left,center;
}
 
.pallette-content-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-max-width11 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1000px;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-heading-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.pallette-prehead12 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-prehead13 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  display: none;
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-main-title2 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  margin-top: -6px;
  text-align: center;
  font-family: "Goldman";
  font-weight: 700;
  line-height: 0.95;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-hero-fade2 {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-hero3 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(255, 255, 255, 0) 70.00%),url("/photos/contact/image1%20(1)-min%20(1)%20cropped.jpg");
  background-position: center;
}
 
.pallette-max-width12 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  align-self: center;
  border-radius: var(--dl-radius-radius-radius2);
  flex-direction: column;
}
 
.pallette-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.pallette-prehead14 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text117 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  margin-top: -6px;
  margin-left: -3px;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000;
}
 
.pallette-text118 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
}
 
.pallette-text126 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
  display: none;
  text-shadow: 0 0 15px #000000;
}
 
.pallette-hero-fade-single {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-hero4 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 7.00%,rgba(255, 255, 255, 0) 41.00%),url("/photos/home/638f7ce82168a403dfdb20fc%20cropped.jpg");
  background-position: center;
}
 
.pallette-content-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-max-width13 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1000px;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-heading-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.pallette-prehead15 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-prehead16 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  display: none;
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-main-title3 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  margin-top: -6px;
  text-align: center;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000;
}
 
.pallette-hero-fade-center {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-hero5 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 0.00%,rgba(255, 255, 255, 0) 71.00%),linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(255, 255, 255, 0) 3.00%),url("/photos/home/638f7ce82168a403dfdb20fc%20cropped.jpg");
  background-position: center;
}
 
.pallette-content-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-max-width14 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1000px;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-heading-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.pallette-prehead17 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-prehead18 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  display: none;
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-main-title4 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  margin-top: -6px;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000;
}
 
.pallette-text136 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  padding-top: var(--dl-space-space-halfunit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-text142 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  display: none;
  padding-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
  padding-bottom: var(--dl-space-space-unit);
}
 
.pallette-link2 {
  margin-top: var(--dl-space-space-halfunit);
}
 
.pallette-divider2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-quadruple);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-meet-the-founder-page-cut {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/photos/home/dave-robinson-b2kgtwnnbd4-unsplash%20edited1-1500w.jpg");
  background-position: top;
}
 
.pallette-gradient-container1 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: none;
  z-index: 1;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(44, 62, 80, 0) 1.00%,rgba(44, 62, 80, 0) 99.00%,rgb(38, 43, 51) 100.00%);
}
 
.pallette-blur-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(6px) contrast(80%);
}
 
.pallette-max-width15 {
  display: flex;
  max-width: 1000px;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row-reverse;
  padding-bottom: var(--dl-space-space-fiveunits);
}
 
.pallette-container12 {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  z-index: 101;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-heading-container14 {
  display: flex;
  align-self: center;
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
 
.pallette-prehead19 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #fffa, 0 0 30px #fffa;
  margin-bottom: -4px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text145 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 10px #fffa, 0 0 30px #fffa;
  letter-spacing: -1px;
}
 
.pallette-text149 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  display: none;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.pallette-content1 {
  display: flex;
  align-self: center;
  flex-direction: row;
}
 
.pallette-line {
  flex: 0 0 auto;
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-style: solid;
  border-width: 2px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 5px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
 
.pallette-section-title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-text152 {
  text-shadow: 0 0 10px #fffa;
}
 
.pallette-text155 {
  fill: var(--dl-color-gray-200);
  color: var(--dl-color-gray-200);
  text-shadow: 0 0 10px #fffa;
}
 
.pallette-image1 {
  bottom: 0px;
  height: 90%;
  z-index: 0;
  position: absolute;
  align-self: center;
  margin-right: 25rem;
}
 
.pallette-gradient-container2 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: none;
  z-index: 1;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(44, 62, 80, 0) 10.00%,rgba(44, 62, 80, 0) 90.00%,rgb(38, 43, 51) 100.00%);
}
 
.pallette-mission {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-max-width16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.pallette-image10 {
  flex: 0 0 auto;
  width: 90%;
  height: 500px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/about/img_98531-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-float1 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 400px;
  margin: auto;
  display: flex;
  position: absolute;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-content2 {
  flex: 0 0 auto;
  width: 600px;
  height: 400px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-prehead20 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text161 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-text162 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
}
 
.pallette-text165 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
}
 
.pallette-mission-reverse {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-max-width17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.pallette-image11 {
  flex: 0 0 auto;
  width: 90%;
  height: 500px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/about/img_98531-1500w.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.pallette-float2 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 400px;
  margin: auto;
  display: flex;
  position: absolute;
  max-width: 1000px;
  align-items: flex-end;
  flex-direction: column;
}
 
.pallette-content3 {
  flex: 0 0 auto;
  width: 600px;
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
 
.pallette-prehead21 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text166 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #000000, 0 0 5px #000000;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-text167 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
}
 
.pallette-text170 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
}
 
.pallette-mission-small {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-max-width18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-content4 {
  flex: 0 0 auto;
  width: 600px;
  height: 400px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  align-items: flex-start;
  margin-right: -15rem;
  flex-direction: column;
}
 
.pallette-prehead22 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text171 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-text172 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
}
 
.pallette-text175 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
}
 
.pallette-image12 {
  flex: 0 0 auto;
  width: 50%;
  height: 500px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/about/img_98531-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-mission-small-cover1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-max-width19 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-image13 {
  flex: 0 0 auto;
  width: 75%;
  height: 500px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.6) 2.00%,rgba(38, 43, 51, 0.6) 100.00%),url("/photos/about/img_98531-1500w.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-content5 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 600px;
  bottom: 0px;
  height: 400px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
 
.pallette-prehead23 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text176 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #000000, 0 0 5px #000000;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-text177 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
}
 
.pallette-text180 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
}
 
.pallette-mission-cover {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-max-width20 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.pallette-image14 {
  flex: 0 0 auto;
  width: 100%;
  height: 500px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.6) 2.00%,rgba(38, 43, 51, 0.6) 98.00%,rgb(38, 43, 51) 100.00%),url("/photos/about/img_98531-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-content6 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 600px;
  bottom: 0px;
  height: 400px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
 
.pallette-prehead24 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text181 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #000000, 0 0 5px #000000;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-text182 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
}
 
.pallette-text185 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
}
 
.pallette-mission-cover-dark {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-max-width21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.pallette-image15 {
  flex: 0 0 auto;
  width: 100%;
  height: 500px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.9) 3.00%,rgba(38, 43, 51, 0.9) 98.00%,rgb(38, 43, 51) 100.00%),url("/photos/about/img_98531-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-content7 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 600px;
  bottom: 0px;
  height: 400px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-prehead25 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text186 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-text187 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
}
 
.pallette-text190 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
}
 
.pallette-mission-cascade {
  flex: 0 0 auto;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-max-width22 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-image16 {
  top: 0px;
  flex: 0 0 auto;
  left: 400px;
  right: 0px;
  width: 35%;
  margin: auto;
  display: flex;
  z-index: 5;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 10px 20px 0px #000000;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.7) 3.00%,rgba(38, 43, 51, 0.7) 100.00%),url("/photos/about/img_98531-1500w.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-image17 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 35%;
  bottom: 0px;
  margin: auto;
  display: flex;
  z-index: 4;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 10px 20px 0px #000000;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.7) 3.00%,rgba(38, 43, 51, 0.7) 100.00%),url("/photos/about/img_98531-1500w.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-image18 {
  flex: 0 0 auto;
  left: 0px;
  right: 400px;
  width: 35%;
  bottom: 0px;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 10px 20px 0px #000000;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.7) 3.00%,rgba(38, 43, 51, 0.7) 100.00%),url("/photos/about/img_98531-1500w.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-content8 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 600px;
  bottom: 0px;
  height: 400px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
 
.pallette-prehead26 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text191 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #000000, 0 0 5px #000000;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-text192 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
}
 
.pallette-text195 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
}
 
.pallette-divider3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-quadruple);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-mission-layered {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-heading1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.pallette-prehead27 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text197 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-row10 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.pallette-image19 {
  flex: 0 0 auto;
  width: 75%;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/162040081_463948628084164_9141381592977607458_n.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-container13 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: absolute;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row;
}
 
.pallette-text198 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-title10 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text199 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio10 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-row11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
 
.pallette-image20 {
  flex: 0 0 auto;
  width: 75%;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/234775954_869307217030980_5059897748366147267_n.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.pallette-container14 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: absolute;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
 
.pallette-text200 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-title11 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text201 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio11 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-row12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.pallette-image21 {
  flex: 0 0 auto;
  width: 75%;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/337428342_553691676601498_7416457620280275304_n-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-container15 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: absolute;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row;
}
 
.pallette-text202 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-title12 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text203 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio12 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-mission-layered2 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-heading2 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.pallette-prehead28 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text204 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-row13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.pallette-image22 {
  flex: 0 0 auto;
  width: 60%;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/img_0147-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-container16 {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
 
.pallette-text205 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.pallette-title13 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text206 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio13 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-row14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.pallette-image23 {
  flex: 0 0 auto;
  width: 60%;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/234775954_869307217030980_5059897748366147267_n.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-container17 {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
 
.pallette-text207 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.pallette-title14 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text208 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio14 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-row15 {
  flex: 0 0 auto;
  width: 100%;
  display: none;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.pallette-image24 {
  flex: 0 0 auto;
  width: 60%;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/about/img_98531-1500w.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.pallette-container18 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
 
.pallette-text209 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-title15 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text210 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio15 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-row16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.pallette-image25 {
  flex: 0 0 auto;
  width: 60%;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/162040081_463948628084164_9141381592977607458_n.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-container19 {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
 
.pallette-text211 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.pallette-title16 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text212 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio16 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-mission-layered3 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-heading3 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.pallette-prehead29 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text213 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-row17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
 
.pallette-image26 {
  flex: 0 0 auto;
  width: 80%;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/162040081_463948628084164_9141381592977607458_n.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.pallette-container20 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: absolute;
  align-items: flex-start;
  flex-direction: row;
}
 
.pallette-text214 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.pallette-title17 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text215 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio17 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-row18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.pallette-image27 {
  flex: 0 0 auto;
  width: 80%;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/234775954_869307217030980_5059897748366147267_n.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-container21 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: absolute;
  align-items: flex-start;
  flex-direction: row;
}
 
.pallette-text216 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.pallette-title18 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text217 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio18 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-row19 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
 
.pallette-image28 {
  flex: 0 0 auto;
  width: 80%;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/image3%20(1)-min%20(2).jpeg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.pallette-container22 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: absolute;
  align-items: flex-start;
  flex-direction: row;
}
 
.pallette-text218 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.pallette-title19 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text219 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio19 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-mission-layered4 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-container23 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row;
}
 
.pallette-collumn1 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-item10 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
 
.pallette-image29 {
  flex: 0 0 auto;
  width: 400px;
  height: 600px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(180deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/162040081_463948628084164_9141381592977607458_n.jpg");
  background-position: top left,left;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.pallette-container24 {
  flex: 0 0 auto;
  width: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
 
.pallette-text220 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-title20 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text221 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio20 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
 
.pallette-image30 {
  flex: 0 0 auto;
  width: 400px;
  height: 600px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(180deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/337428342_553691676601498_7416457620280275304_n-1500w.jpg");
  background-position: top left,left;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.pallette-container25 {
  flex: 0 0 auto;
  width: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
 
.pallette-text222 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-title21 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text223 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio21 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-collumn2 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-heading4 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  margin-top: var(--dl-space-space-tenunits);
  align-items: flex-start;
  margin-bottom: 8rem;
  flex-direction: column;
}
 
.pallette-prehead30 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text224 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-item12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
 
.pallette-image31 {
  flex: 0 0 auto;
  width: 400px;
  height: 600px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(180deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/photo package/234775954_869307217030980_5059897748366147267_n.jpg");
  background-position: top left,left;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.pallette-container26 {
  flex: 0 0 auto;
  width: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
 
.pallette-text225 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-title22 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text226 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio22 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-team {
  width: 100%;
  display: flex;
  overflow: visible;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-heading-container15 {
  display: flex;
  z-index: 1;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.pallette-prehead31 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
}
 
.pallette-text227 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-align: center;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
}
 
.pallette-team-container {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-collumn3 {
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.pallette-person1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-image32 {
  width: 400px;
  object-fit: cover;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.pallette-text228 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-bottom: 200px;
  flex-direction: column;
}
 
.pallette-title23 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text229 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 10px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio23 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-person2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  margin-top: -200px;
  align-items: flex-start;
  flex-direction: row;
}
 
.pallette-text230 {
  width: 400px;
  display: flex;
  align-self: center;
  margin-top: 200px;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-title24 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-text231 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 10px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.pallette-bio24 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-image33 {
  width: 400px;
  object-fit: cover;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.pallette-divider4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-quadruple);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-grid {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-heading-container16 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-prehead32 {
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000;
}
 
.pallette-text233 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.pallette-mission-small-cover2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-item13 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-image34 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/home/photo package/image3%20(1)-min%20(2).jpeg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details10 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head10 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.pallette-description10 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item14 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-image35 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/home/photo package/image3%20(1)-min%20(2).jpeg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details11 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head11 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.pallette-description11 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item15 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-image36 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/home/photo package/image3%20(1)-min%20(2).jpeg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details12 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head12 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.pallette-description12 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item16 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-image37 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/home/photo package/image3%20(1)-min%20(2).jpeg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details13 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head13 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.pallette-description13 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item17 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-image38 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/home/photo package/image3%20(1)-min%20(2).jpeg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details14 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head14 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.pallette-description14 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item18 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-image39 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/home/photo package/image3%20(1)-min%20(2).jpeg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details15 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head15 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.pallette-description15 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-chaos-grid {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-heading-container17 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-prehead33 {
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000;
}
 
.pallette-text237 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.pallette-mission-small-cover3 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-row20 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-item19 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image40 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.8) 0.00%,rgba(38, 43, 51, 0.8) 100.00%),url("/photos/home/photo package/162040081_463948628084164_9141381592977607458_n.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details16 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head16 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description16 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item20 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image41 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.8) 0.00%,rgba(38, 43, 51, 0.8) 100.00%),url("/photos/home/photo package/234775954_869307217030980_5059897748366147267_n.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details17 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head17 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description17 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-row21 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-item21 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image42 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.8) 0.00%,rgba(38, 43, 51, 0.8) 100.00%),url("/photos/home/photo package/image3%20(1)-min%20(2).jpeg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details18 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head18 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description18 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item22 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image43 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.8) 0.00%,rgba(38, 43, 51, 0.8) 100.00%),url("/photos/home/photo package/img_0147-1500w.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details19 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head19 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description19 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-row22 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-item23 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image44 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.8) 0.00%,rgba(38, 43, 51, 0.8) 100.00%),url("/photos/home/photo package/337428342_553691676601498_7416457620280275304_n-1500w.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details20 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head20 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description20 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item24 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image45 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.8) 0.00%,rgba(38, 43, 51, 0.8) 100.00%),url("/photos/home/218101624_228229749022861_203455337673028463_n.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details21 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head21 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description21 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-faded-chaos-grid {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-heading-container18 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-prehead34 {
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000;
}
 
.pallette-text241 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.pallette-mission-small-cover4 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-row23 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-item25 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image46 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgba(38, 43, 51, 0.8) 0.00%,rgb(38, 43, 51) 98.00%),url("/photos/home/photo package/162040081_463948628084164_9141381592977607458_n.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-details22 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head22 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description22 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item26 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image47 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.8) 0.00%,rgba(38, 43, 51, 0.8) 100.00%),url("/photos/home/photo package/234775954_869307217030980_5059897748366147267_n.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details23 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head23 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description23 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-row24 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-item27 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image48 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.8) 0.00%,rgba(38, 43, 51, 0.8) 100.00%),url("/photos/home/photo package/image3%20(1)-min%20(2).jpeg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details24 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head24 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description24 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item28 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image49 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.8) 0.00%,rgb(38, 43, 51) 100.00%),url("/photos/home/photo package/img_0147-1500w.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.pallette-details25 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head25 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description25 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-row25 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-item29 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image50 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.8) 100.00%),url("/photos/home/photo package/337428342_553691676601498_7416457620280275304_n-1500w.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-details26 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head26 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description26 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-item30 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image51 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.8) 0.00%,rgba(38, 43, 51, 0.8) 100.00%),url("/photos/home/218101624_228229749022861_203455337673028463_n.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details27 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-head27 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-description27 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.pallette-faded-chaos-grid-labeled {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-heading-container19 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.pallette-prehead35 {
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000;
}
 
.pallette-text245 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.pallette-mission-small-cover5 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.pallette-row26 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-item31 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image52 {
  flex: 0 0 auto;
  width: 100%;
  height: 75%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 100.00%),url("/photos/home/photo package/162040081_463948628084164_9141381592977607458_n.jpg");
  background-repeat: center;
  background-position: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-details28 {
  flex: 1;
  display: flex;
  z-index: 5;
  align-items: stretch;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
 
.pallette-head28 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-item32 {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image53 {
  flex: 0 0 auto;
  width: 100%;
  height: 75%;
  display: flex;
  overflow: hidden;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/home/photo package/234775954_869307217030980_5059897748366147267_n.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details29 {
  flex: 1;
  display: flex;
  z-index: 5;
  align-items: stretch;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
 
.pallette-head29 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-row27 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-item33 {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image54 {
  flex: 0 0 auto;
  width: 100%;
  height: 75%;
  display: flex;
  overflow: hidden;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/home/photo package/337428342_553691676601498_7416457620280275304_n-1500w.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details30 {
  flex: 1;
  display: flex;
  z-index: 5;
  align-items: stretch;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
 
.pallette-head30 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-item34 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image55 {
  flex: 0 0 auto;
  width: 100%;
  height: 75%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 100.00%),url("/photos/home/photo package/image3%20(1)-min%20(2).jpeg");
  background-repeat: center;
  background-position: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.pallette-details31 {
  flex: 1;
  display: flex;
  z-index: 5;
  align-items: stretch;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
 
.pallette-head31 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-row28 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.pallette-item35 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image56 {
  flex: 0 0 auto;
  width: 100%;
  height: 75%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 100.00%),url("/photos/home/photo package/img_0147-1500w.jpg");
  background-repeat: center;
  background-position: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.pallette-details32 {
  flex: 1;
  display: flex;
  z-index: 5;
  align-items: stretch;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
 
.pallette-head32 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-item36 {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-image57 {
  flex: 0 0 auto;
  width: 100%;
  height: 75%;
  display: flex;
  overflow: hidden;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/home/218101624_228229749022861_203455337673028463_n.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.pallette-details33 {
  flex: 1;
  display: flex;
  z-index: 5;
  align-items: stretch;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
 
.pallette-head33 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.pallette-divider5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-quadruple);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-cta {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-max-width23 {
  width: 100%;
  display: flex;
  z-index: 10;
  position: relative;
  align-self: flex-end;
  border-radius: var(--dl-radius-radius-radius2);
  flex-direction: row;
  justify-content: flex-end;
}
 
.pallette-heading-container20 {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: absolute;
  max-width: 1000px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
 
.pallette-content9 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.pallette-text251 {
  width: 500px;
  text-align: left;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
  margin-bottom: var(--dl-space-space-unit);
}
 
.pallette-image58 {
  width: 85%;
  height: 500px;
  display: flex;
  overflow: hidden;
  position: relative;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column-reverse;
  justify-content: center;
  background-repeat: top left;
  background-position: top left,bottom;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.pallette-image59 {
  width: 100%;
  object-fit: cover;
}
 
.pallette-container27 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%);
}
 
.pallette-text253 {
  display: inline-block;
}
 
.pallette-text254 {
  display: inline-block;
}
 
.pallette-text255 {
  display: inline-block;
}
 
.pallette-text258 {
  display: inline-block;
}
 
.pallette-text259 {
  display: inline-block;
}
 
.pallette-text260 {
  display: inline-block;
}
 
.pallette-text263 {
  display: inline-block;
}
 
.pallette-text264 {
  display: inline-block;
}
 
.pallette-text265 {
  display: inline-block;
}
 
.pallette-text268 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .pallette-main-title1 {
    font-size: 3.2rem;
  }
  .pallette-text104 {
    text-align: left;
  }
  .pallette-text110 {
    max-width: 520px;
    text-align: left;
  }
  .pallette-text117 {
    font-size: 2.5rem;
  }
  .pallette-main-title4 {
    font-size: 3.2rem;
  }
  .pallette-text136 {
    text-align: left;
  }
  .pallette-text142 {
    max-width: 520px;
    text-align: left;
  }
  .pallette-image1 {
    margin-right: 35%;
  }
  .pallette-text198 {
    width: 350px;
  }
  .pallette-text200 {
    width: 350px;
  }
  .pallette-text202 {
    width: 350px;
  }
  .pallette-text205 {
    width: 350px;
  }
  .pallette-text207 {
    width: 350px;
  }
  .pallette-text209 {
    width: 350px;
  }
  .pallette-text211 {
    width: 350px;
  }
  .pallette-text214 {
    width: 350px;
  }
  .pallette-text216 {
    width: 350px;
  }
  .pallette-text218 {
    width: 350px;
  }
  .pallette-text220 {
    width: 350px;
  }
  .pallette-text222 {
    width: 350px;
  }
  .pallette-text225 {
    width: 350px;
  }
  .pallette-team {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pallette-team-container {
    gap: 0;
    flex-direction: column;
  }
  .pallette-image32 {
    width: 350px;
  }
  .pallette-text228 {
    width: 350px;
  }
  .pallette-text230 {
    width: 350px;
  }
  .pallette-image33 {
    width: 350px;
  }
}
 
@media(max-width: 767px) {
  .pallette-hero1 {
    justify-content: center;
  }
  .pallette-max-width10 {
    align-items: center;
  }
  .pallette-main-title1 {
    font-size: 42px;
  }
  .pallette-text104 {
    display: none;
    text-align: center;
  }
  .pallette-text110 {
    fill: var(--dl-color-gray-700);
    color: var(--dl-color-gray-700);
    display: block;
  }
  .pallette-hero2 {
    justify-content: center;
  }
  .pallette-max-width11 {
    align-items: center;
  }
  .pallette-hero3 {
    align-content: center;
  }
  .pallette-text117 {
    font-size: 32px;
  }
  .pallette-text118 {
    display: none;
  }
  .pallette-text126 {
    display: block;
  }
  .pallette-hero4 {
    justify-content: center;
  }
  .pallette-max-width13 {
    align-items: center;
  }
  .pallette-hero5 {
    justify-content: center;
  }
  .pallette-max-width14 {
    align-items: center;
  }
  .pallette-main-title4 {
    font-size: 42px;
  }
  .pallette-text136 {
    display: none;
    text-align: center;
  }
  .pallette-text142 {
    fill: var(--dl-color-gray-700);
    color: var(--dl-color-gray-700);
    display: block;
  }
  .pallette-max-width15 {
    gap: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .pallette-container12 {
    width: 100%;
    margin-bottom: 75%;
  }
  .pallette-heading-container14 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .pallette-prehead19 {
    text-align: center;
  }
  .pallette-content1 {
    width: 80%;
  }
  .pallette-image1 {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
  .pallette-max-width16 {
    flex-direction: column;
  }
  .pallette-image10 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-float1 {
    width: 100%;
    height: auto;
    position: relative;
    max-width: 600px;
    margin-top: -10rem;
  }
  .pallette-content2 {
    max-width: 100%;
  }
  .pallette-max-width17 {
    flex-direction: column;
  }
  .pallette-image11 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-float2 {
    width: 100%;
    height: auto;
    position: relative;
    max-width: 600px;
    margin-top: -10rem;
  }
  .pallette-content3 {
    max-width: 100%;
  }
  .pallette-max-width18 {
    flex-direction: column;
  }
  .pallette-content4 {
    max-width: 100%;
  }
  .pallette-image12 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-max-width19 {
    flex-direction: column;
  }
  .pallette-image13 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-content5 {
    max-width: 100%;
  }
  .pallette-max-width20 {
    flex-direction: column;
  }
  .pallette-image14 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-content6 {
    max-width: 100%;
  }
  .pallette-max-width21 {
    flex-direction: column;
  }
  .pallette-image15 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-content7 {
    max-width: 100%;
  }
  .pallette-max-width22 {
    flex-direction: column;
  }
  .pallette-image16 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-image17 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-image18 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-content8 {
    max-width: 100%;
  }
  .pallette-row10 {
    flex-direction: column;
  }
  .pallette-image19 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text198 {
    width: 230px;
  }
  .pallette-row11 {
    flex-direction: column;
  }
  .pallette-image20 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text200 {
    width: 230px;
  }
  .pallette-row12 {
    flex-direction: column;
  }
  .pallette-image21 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text202 {
    width: 230px;
  }
  .pallette-row13 {
    flex-direction: column;
  }
  .pallette-image22 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text205 {
    width: 230px;
  }
  .pallette-row14 {
    flex-direction: column;
  }
  .pallette-image23 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text207 {
    width: 230px;
  }
  .pallette-row15 {
    flex-direction: column;
  }
  .pallette-image24 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text209 {
    width: 230px;
  }
  .pallette-row16 {
    flex-direction: column;
  }
  .pallette-image25 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text211 {
    width: 230px;
  }
  .pallette-row17 {
    flex-direction: column;
  }
  .pallette-image26 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text214 {
    width: 230px;
  }
  .pallette-row18 {
    flex-direction: column;
  }
  .pallette-image27 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text216 {
    width: 230px;
  }
  .pallette-row19 {
    flex-direction: column;
  }
  .pallette-image28 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text218 {
    width: 230px;
  }
  .pallette-item10 {
    flex-direction: column;
  }
  .pallette-image29 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text220 {
    width: 230px;
  }
  .pallette-item11 {
    flex-direction: column;
  }
  .pallette-image30 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text222 {
    width: 230px;
  }
  .pallette-item12 {
    flex-direction: column;
  }
  .pallette-image31 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-text225 {
    width: 230px;
  }
  .pallette-heading-container15 {
    align-items: center;
  }
  .pallette-prehead31 {
    text-align: center;
  }
  .pallette-team-container {
    padding: 0;
  }
  .pallette-image32 {
    width: 250px;
  }
  .pallette-text228 {
    width: 230px;
  }
  .pallette-person2 {
    margin-top: -200px;
  }
  .pallette-text230 {
    width: 230px;
    margin-top: 50px;
  }
  .pallette-image33 {
    width: 250px;
  }
  .pallette-grid {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pallette-heading-container16 {
    align-items: center;
  }
  .pallette-prehead32 {
    text-align: center;
  }
  .pallette-image34 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details10 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image35 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details11 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image36 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details12 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image37 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details13 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image38 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details14 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image39 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details15 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-chaos-grid {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pallette-heading-container17 {
    align-items: center;
  }
  .pallette-prehead33 {
    text-align: center;
  }
  .pallette-row20 {
    flex-direction: column;
  }
  .pallette-image40 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details16 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image41 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details17 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-row21 {
    flex-direction: column;
  }
  .pallette-image42 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details18 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image43 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details19 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-row22 {
    flex-direction: column;
  }
  .pallette-image44 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details20 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image45 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details21 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-faded-chaos-grid {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pallette-heading-container18 {
    align-items: center;
  }
  .pallette-prehead34 {
    text-align: center;
  }
  .pallette-row23 {
    flex-direction: column;
  }
  .pallette-image46 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details22 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image47 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details23 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-row24 {
    flex-direction: column;
  }
  .pallette-image48 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details24 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image49 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details25 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-row25 {
    flex-direction: column;
  }
  .pallette-image50 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details26 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image51 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details27 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-faded-chaos-grid-labeled {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .pallette-heading-container19 {
    align-items: center;
  }
  .pallette-prehead35 {
    text-align: center;
  }
  .pallette-row26 {
    flex-direction: column;
  }
  .pallette-image52 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details28 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image53 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details29 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-row27 {
    flex-direction: column;
  }
  .pallette-image54 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details30 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image55 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details31 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-row28 {
    flex-direction: column;
  }
  .pallette-image56 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details32 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-image57 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .pallette-details33 {
    max-width: 100%;
    margin-left: 0;
  }
  .pallette-max-width23 {
    align-items: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column-reverse;
  }
  .pallette-heading-container20 {
    width: 100%;
    position: static;
    margin-top: -100px;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0;
  }
  .pallette-content9 {
    padding: var(--dl-space-space-unit);
  }
  .pallette-text251 {
    width: 100%;
  }
  .pallette-image58 {
    width: 100%;
    height: 300px;
    border-top-right-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
  }
  .pallette-image59 {
    height: 100%;
  }
  .pallette-container27 {
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%);
  }
}
 
@media(max-width: 479px) {
  .pallette-max-width15 {
    gap: 24px;
  }
  .pallette-text145 {
    display: none;
  }
  .pallette-text149 {
    display: block;
    font-size: 2rem;
  }
  .pallette-text198 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text200 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text202 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text205 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text207 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text209 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text211 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text214 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text216 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text218 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text220 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text222 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text225 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-text227 {
    font-size: 2rem;
  }
  .pallette-person1 {
    flex-direction: column;
  }
  .pallette-image32 {
    width: 300px;
  }
  .pallette-text228 {
    width: 300px;
    margin-bottom: 0px;
  }
  .pallette-person2 {
    margin-top: 0px;
    flex-direction: column-reverse;
  }
  .pallette-text230 {
    width: 300px;
    margin-top: 0px;
  }
  .pallette-image33 {
    width: 300px;
  }
  .pallette-text233 {
    font-size: 2rem;
  }
  .pallette-text237 {
    font-size: 2rem;
  }
  .pallette-text241 {
    font-size: 2rem;
  }
  .pallette-text245 {
    font-size: 2rem;
  }
  .pallette-cta {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .pallette-text250 {
    font-size: 2rem;
  }
  .pallette-image58 {
    height: 200px;
  }
  .pallette-text256 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pallette-text266 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
