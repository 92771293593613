.contact-popup-container {
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.contact-popup-bg {
  top: 0;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 200;
  position: fixed;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(4px);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
 
.contact-popup-content-container {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  position: relative;
  box-shadow: 0 5px 15px #000000;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-key-gunmetal);
}
 
.contact-popup-text10 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 15px #000000;
}
 
.contact-popup-text11 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 1.6rem;
  margin-top: -8px;
  text-align: center;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 15px #000000;
  margin-bottom: var(--dl-space-space-halfunit);
  letter-spacing: -1px;
  text-decoration: none;
}
 
.contact-popup-text14 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
  width: 400px;
  font-size: 16px;
  margin-top: var(--dl-space-space-quarterunit);
  text-align: center;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 15px #000000;
  letter-spacing: -1px;
  text-transform: none;
  text-decoration: none;
}
 
.contact-popup-text16 {
  color: var(--dl-color-key-blueribbon);
}
 
.contact-popup-image {
  top: 20px;
  right: 20px;
  width: 15px;
  cursor: pointer;
  position: absolute;
  object-fit: cover;
}
 

 

 

 

