.project-highlight-project-highlight {
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
}
 
.project-highlight-heading-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.project-highlight-prehead {
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000;
}
 
.project-highlight-text10 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.project-highlight-project0 {
  width: 100%;
  display: flex;
  max-width: 1000px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
 
.project-highlight-photo1 {
  width: 70%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.project-highlight-image5 {
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.project-highlight-container10 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.05) 53.00%,rgba(255, 255, 255, 0) 100.00%);
}
 
.project-highlight-details1 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  z-index: 5;
  position: relative;
  align-items: stretch;
  margin-left: -5rem;
  flex-direction: column;
  justify-content: center;
}
 
.project-highlight-head1 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.project-highlight-location1 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
 
.project-highlight-text15 {
  color: var(--dl-color-gray-900);
}
 
.project-highlight-description1 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.project-highlight-container11 {
  flex: 0 0 auto;
  left: -4px;
  width: 2px;
  height: 90%;
  display: flex;
  position: absolute;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.project-highlight-project1 {
  width: 100%;
  display: flex;
  max-width: 1000px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
 
.project-highlight-photo2 {
  width: 70%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.project-highlight-image6 {
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.project-highlight-container12 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.05) 53.00%,rgba(255, 255, 255, 0) 100.00%);
}
 
.project-highlight-details2 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  z-index: 5;
  position: relative;
  align-items: stretch;
  margin-left: -5rem;
  flex-direction: column;
  justify-content: center;
}
 
.project-highlight-head2 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.project-highlight-location2 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
 
.project-highlight-text17 {
  color: var(--dl-color-gray-900);
}
 
.project-highlight-description2 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.project-highlight-container13 {
  flex: 0 0 auto;
  left: -4px;
  width: 2px;
  height: 90%;
  display: flex;
  position: absolute;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.project-highlight-project2 {
  width: 100%;
  display: flex;
  max-width: 1000px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
 
.project-highlight-photo3 {
  width: 70%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.project-highlight-image7 {
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.project-highlight-container14 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.05) 53.00%,rgba(255, 255, 255, 0) 100.00%);
}
 
.project-highlight-details3 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  z-index: 5;
  position: relative;
  align-items: stretch;
  margin-left: -5rem;
  flex-direction: column;
  justify-content: center;
}
 
.project-highlight-head3 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.project-highlight-location3 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
 
.project-highlight-text19 {
  color: var(--dl-color-gray-900);
}
 
.project-highlight-description3 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.project-highlight-container15 {
  flex: 0 0 auto;
  left: -4px;
  width: 2px;
  height: 90%;
  display: flex;
  position: absolute;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.project-highlight-project3 {
  width: 100%;
  display: flex;
  max-width: 1000px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
 
.project-highlight-photo4 {
  width: 70%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.project-highlight-image8 {
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.project-highlight-container16 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.05) 53.00%,rgba(255, 255, 255, 0) 100.00%);
}
 
.project-highlight-details4 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  z-index: 5;
  position: relative;
  align-items: stretch;
  margin-left: -5rem;
  flex-direction: column;
  padding-bottom: 0.5em;
  justify-content: center;
}
 
.project-highlight-head4 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.project-highlight-location4 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
 
.project-highlight-text21 {
  color: var(--dl-color-gray-900);
}
 
.project-highlight-description4 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.project-highlight-container17 {
  flex: 0 0 auto;
  left: -4px;
  width: 2px;
  height: 90%;
  display: flex;
  position: absolute;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.project-highlight-project4 {
  width: 100%;
  display: flex;
  max-width: 1000px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
 
.project-highlight-photo5 {
  width: 70%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.project-highlight-image9 {
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.project-highlight-container18 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.05) 53.00%,rgba(255, 255, 255, 0) 100.00%);
}
 
.project-highlight-details5 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  z-index: 5;
  position: relative;
  align-items: stretch;
  margin-left: -5rem;
  flex-direction: column;
  padding-bottom: 0.5em;
  justify-content: center;
}
 
.project-highlight-head5 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.project-highlight-location5 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
 
.project-highlight-text23 {
  color: var(--dl-color-gray-900);
}
 
.project-highlight-description5 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 15px #000000aa, 0 0 10px #000000aa, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.project-highlight-container19 {
  flex: 0 0 auto;
  left: -4px;
  width: 2px;
  height: 90%;
  display: flex;
  position: absolute;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.project-highlight-project-selection {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.project-highlight-image0 {
  width: 18%;
  cursor: pointer;
  opacity: 0.35;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.project-highlight-image1 {
  width: 18%;
  cursor: pointer;
  opacity: 0.35;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.project-highlight-image2 {
  width: 18%;
  cursor: pointer;
  opacity: 0.35;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.project-highlight-image3 {
  width: 18%;
  cursor: pointer;
  opacity: 0.35;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.project-highlight-image4 {
  width: 18%;
  cursor: pointer;
  opacity: 0.35;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
}
 
@media(max-width: 991px) {
  .project-highlight-project0 {
    justify-content: center;
  }
  .project-highlight-project1 {
    justify-content: center;
  }
  .project-highlight-project2 {
    justify-content: center;
  }
  .project-highlight-project3 {
    justify-content: center;
  }
  .project-highlight-project4 {
    justify-content: center;
  }
}
 
@media(max-width: 767px) {
  .project-highlight-project-highlight {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .project-highlight-heading-container {
    align-items: center;
  }
  .project-highlight-prehead {
    text-align: center;
  }
  .project-highlight-project0 {
    flex-direction: column;
  }
  .project-highlight-photo1 {
    width: 100%;
  }
  .project-highlight-details1 {
    max-width: 100%;
    margin-left: 0;
  }
  .project-highlight-project1 {
    flex-direction: column;
  }
  .project-highlight-photo2 {
    width: 100%;
  }
  .project-highlight-details2 {
    max-width: 100%;
    margin-left: 0;
  }
  .project-highlight-project2 {
    flex-direction: column;
  }
  .project-highlight-photo3 {
    width: 100%;
  }
  .project-highlight-details3 {
    max-width: 100%;
    margin-left: 0;
  }
  .project-highlight-project3 {
    flex-direction: column;
  }
  .project-highlight-photo4 {
    width: 100%;
  }
  .project-highlight-details4 {
    max-width: 100%;
    margin-left: 0;
  }
  .project-highlight-project4 {
    flex-direction: column;
  }
  .project-highlight-photo5 {
    width: 100%;
  }
  .project-highlight-details5 {
    max-width: 100%;
    margin-left: 0;
  }
}
 
@media(max-width: 479px) {
  .project-highlight-text10 {
    font-size: 2rem;
  }
}


.project-selection{
  transition: .2s;
}

.project-selection:hover{
  opacity: .75;
}
