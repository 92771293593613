.bathrooms-container1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.bathrooms-hero-fade-single {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
  justify-content: center;
}
 
.bathrooms-hero {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.8) 38.00%,rgba(38, 43, 51, 0.5) 50.00%,rgba(38, 43, 51, 0) 60.00%),url("/photos/bathrooms/snapinsta.app_274985307_1167788940695309_6054238171269608400_n_1080-min%20cropped-1500w.jpg");
  background-position: center;
}
 
.bathrooms-content-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
 
.bathrooms-max-width1 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.bathrooms-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.bathrooms-prehead1 {
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000, 0 0 30px #000000, 0 0 4px #000000;
}
 
.bathrooms-main-title {
  text-align: left;
}
 
.bathrooms-text14 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  max-width: 500px;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.bathrooms-showcase {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.bathrooms-heading {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.bathrooms-prehead2 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.bathrooms-text15 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-align: center;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  letter-spacing: 1px;
}
 
.bathrooms-row1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.bathrooms-image10 {
  flex: 0 0 auto;
  width: 66%;
  height: 400px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 43.00%),url("/photos/bathrooms/234775954_869307217030980_5059897748366147267_n1-1400w.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.bathrooms-container2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 390px;
  align-items: flex-start;
  flex-direction: row;
}
 
.bathrooms-text19 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.bathrooms-text20 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.bathrooms-bio1 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.bathrooms-text22 {
  color: var(--dl-color-key-iron);
}
 
.bathrooms-row2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.bathrooms-image11 {
  flex: 0 0 auto;
  width: 66%;
  height: 400px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 43.00%),url("/photos/bathrooms/330390426_749024616622009_946065905556702757_n-1400w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.bathrooms-container3 {
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 400px;
  align-items: flex-start;
  flex-direction: row;
}
 
.bathrooms-text23 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.bathrooms-text24 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.bathrooms-bio2 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.bathrooms-text26 {
  color: var(--dl-color-key-iron);
}
 
.bathrooms-row3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.bathrooms-image12 {
  flex: 0 0 auto;
  width: 66%;
  height: 400px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 43.00%),url("/photos/bathrooms/img_2754-2-1400w.png");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.bathrooms-container4 {
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 400px;
  align-items: flex-start;
  flex-direction: row;
}
 
.bathrooms-text27 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.bathrooms-text28 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.bathrooms-bio3 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.bathrooms-text30 {
  color: var(--dl-color-key-iron);
}
 
.bathrooms-luxury-features {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.bathrooms-heading-container2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.bathrooms-text31 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  letter-spacing: 1px;
}
 
.bathrooms-mission-small-cover {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-key-gunmetal);
}
 
.bathrooms-item1 {
  width: 30%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-quadruple);
  flex-direction: column;
}
 
.bathrooms-image13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(180deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 49.00%),url("/photos/bathrooms/features/img_3626-600w.jpeg");
  background-repeat: center;
  background-position: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.bathrooms-details1 {
  display: flex;
  z-index: 5;
  max-width: 400px;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.bathrooms-text34 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
}
 
.bathrooms-item2 {
  width: 30%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-quadruple);
  flex-direction: column;
}
 
.bathrooms-image14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(180deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 49.00%),url("/photos/bathrooms/features/snapinsta.app_232104884_239100611244418_2186567679333204015_n_1080-600w.jpg");
  background-repeat: center;
  background-position: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.bathrooms-details2 {
  display: flex;
  z-index: 5;
  max-width: 400px;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.bathrooms-text35 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
}
 
.bathrooms-item3 {
  width: 30%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-quadruple);
  flex-direction: column;
}
 
.bathrooms-image15 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(180deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 49.00%),url("/photos/bathrooms/features/untitled-600w.png");
  background-repeat: center;
  background-position: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.bathrooms-details3 {
  display: flex;
  z-index: 5;
  max-width: 400px;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.bathrooms-text36 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
}
 
.bathrooms-item4 {
  width: 30%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column-reverse;
}
 
.bathrooms-image16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 49.00%),url("/photos/bathrooms/features/snapinsta.app_234846034_212625374031489_2875048946166191944_n_1080-min-600w.jpg");
  background-repeat: center;
  background-position: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
 
.bathrooms-details4 {
  display: flex;
  z-index: 5;
  max-width: 400px;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.bathrooms-text37 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
}
 
.bathrooms-item5 {
  width: 30%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column-reverse;
}
 
.bathrooms-image17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 49.00%),url("/photos/bathrooms/features/snapinsta.app_229033312_363327048642059_5017745049072500637_n_1080-600w.jpg");
  background-repeat: center;
  background-position: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
 
.bathrooms-details5 {
  display: flex;
  z-index: 5;
  max-width: 400px;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.bathrooms-text38 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
}
 
.bathrooms-item6 {
  width: 30%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column-reverse;
}
 
.bathrooms-image18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 49.00%),url("/photos/bathrooms/features/skylight2-600w.jpg");
  background-repeat: center;
  background-position: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
 
.bathrooms-details6 {
  display: flex;
  z-index: 5;
  max-width: 400px;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.bathrooms-text39 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
}
 
.bathrooms-mirrors-and-lighting {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.bathrooms-max-width2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.bathrooms-float {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.bathrooms-content1 {
  flex: 0 0 auto;
  width: 600px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 5;
  align-items: flex-start;
  margin-right: -15rem;
  flex-direction: column;
}
 
.bathrooms-prehead4 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.bathrooms-text40 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.bathrooms-image19 {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 2;
  margin-right: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 2.00%,rgba(38, 43, 51, 0) 34.00%),url("/photos/bathrooms/330390426_749024616622009_946065905556702757_n-1400w.jpg");
  background-repeat: top;
  background-position: top lefttop left,top;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.bathrooms-text41 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
}
 
.bathrooms-text42 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
}
 
.bathrooms-text44 {
  color: var(--dl-color-gray-900);
}
 
.bathrooms-text46 {
  color: var(--dl-color-gray-900);
}
 
.bathrooms-text48 {
  color: var(--dl-color-gray-900);
}
 
.bathrooms-text50 {
  color: var(--dl-color-gray-900);
}
 
.bathrooms-image20 {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 2;
  margin-right: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 2.00%,rgba(38, 43, 51, 0.8) 29.00%,rgba(38, 43, 51, 0) 58.00%),url("/photos/bathrooms/330390426_749024616622009_946065905556702757_n-1400w.jpg");
  background-repeat: top;
  background-position: top lefttop left,top;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.bathrooms-mirrors-and-lighting2 {
  flex: 0 0 auto;
  width: 100%;
  display: none;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.bathrooms-max-width3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
 
.bathrooms-head7 {
  flex: 0 0 auto;
  display: flex;
  z-index: 5;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.bathrooms-prehead5 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.bathrooms-text52 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.bathrooms-image21 {
  width: 100%;
  height: 400px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 2.00%,rgba(38, 43, 51, 0.8) 31.00%,rgba(38, 43, 51, 0) 57.00%),0d227c80-4696-4ba1-a1bc-8c3262d0ae05;
  background-repeat: top;
  background-position: top lefttop left,top;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
 
.bathrooms-content2 {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  z-index: 5;
  margin-top: -5rem;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.bathrooms-text53 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
}
 
.bathrooms-text54 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
}
 
.bathrooms-text58 {
  color: var(--dl-color-gray-900);
}
 
.bathrooms-text62 {
  color: var(--dl-color-gray-900);
}
 
.bathrooms-text66 {
  color: var(--dl-color-gray-900);
}
 
.bathrooms-text70 {
  color: var(--dl-color-gray-900);
}
 
.bathrooms-cta {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.bathrooms-max-width4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.bathrooms-image22 {
  flex: 0 0 auto;
  width: 100%;
  height: 400px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.8) 2.00%,rgba(38, 43, 51, 0.8) 97.00%,rgb(38, 43, 51) 100.00%),url("/photos/bathrooms/snapinsta.app_230936087_1046867382724008_9056407076715993133_n_1080-min-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.bathrooms-content3 {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: absolute;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
 
.bathrooms-content4 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.bathrooms-text72 {
  text-align: center;
}
 
.bathrooms-text73 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  width: 550px;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
 
.bathrooms-text75 {
  display: inline-block;
}
 
.bathrooms-text76 {
  display: inline-block;
}
 
.bathrooms-text77 {
  display: inline-block;
}
 
.bathrooms-text80 {
  display: inline-block;
}
 
.bathrooms-text81 {
  display: inline-block;
}
 
.bathrooms-text82 {
  display: inline-block;
}
 
.bathrooms-text85 {
  display: inline-block;
}
 
.bathrooms-text86 {
  display: inline-block;
}
 
.bathrooms-text87 {
  display: inline-block;
}
 
.bathrooms-text90 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .bathrooms-text34 {
    height: 80px;
  }
  .bathrooms-text35 {
    height: 80px;
  }
  .bathrooms-text36 {
    height: 80px;
  }
  .bathrooms-text37 {
    height: 80px;
  }
  .bathrooms-text38 {
    height: 80px;
  }
  .bathrooms-text39 {
    height: 80px;
  }
  .bathrooms-content1 {
    width: 400px;
    padding: var(--dl-space-space-unit);
  }
  .bathrooms-text40 {
    margin-right: -15rem;
  }
}
 
@media(max-width: 767px) {
  .bathrooms-hero {
    justify-content: center;
  }
  .bathrooms-max-width1 {
    align-items: center;
  }
  .bathrooms-row1 {
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .bathrooms-image10 {
    width: 90%;
    align-self: flex-end;
  }
  .bathrooms-container2 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
    align-self: flex-end;
  }
  .bathrooms-text19 {
    width: 100%;
  }
  .bathrooms-row2 {
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .bathrooms-image11 {
    width: 90%;
  }
  .bathrooms-container3 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
  }
  .bathrooms-text23 {
    width: 100%;
  }
  .bathrooms-row3 {
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .bathrooms-image12 {
    width: 90%;
    align-self: flex-end;
  }
  .bathrooms-container4 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
    align-self: flex-end;
  }
  .bathrooms-text27 {
    width: 100%;
  }
  .bathrooms-luxury-features {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .bathrooms-heading-container2 {
    align-items: center;
  }
  .bathrooms-prehead3 {
    text-align: center;
  }
  .bathrooms-item1 {
    width: 45%;
  }
  .bathrooms-details1 {
    max-width: 100%;
    margin-left: 0;
  }
  .bathrooms-text34 {
    height: auto;
  }
  .bathrooms-item2 {
    width: 45%;
  }
  .bathrooms-details2 {
    max-width: 100%;
    margin-left: 0;
  }
  .bathrooms-text35 {
    height: auto;
  }
  .bathrooms-item3 {
    width: 45%;
    margin-bottom: var(--dl-space-space-quadruple);
  }
  .bathrooms-details3 {
    max-width: 100%;
    margin-left: 0;
  }
  .bathrooms-text36 {
    height: auto;
  }
  .bathrooms-item4 {
    width: 45%;
    margin-bottom: var(--dl-space-space-quadruple);
  }
  .bathrooms-details4 {
    max-width: 100%;
    margin-left: 0;
  }
  .bathrooms-text37 {
    height: auto;
  }
  .bathrooms-item5 {
    width: 45%;
  }
  .bathrooms-details5 {
    max-width: 100%;
    margin-left: 0;
  }
  .bathrooms-text38 {
    height: auto;
  }
  .bathrooms-item6 {
    width: 45%;
  }
  .bathrooms-details6 {
    max-width: 100%;
    margin-left: 0;
  }
  .bathrooms-text39 {
    height: auto;
  }
  .bathrooms-max-width2 {
    flex-direction: column;
  }
  .bathrooms-content1 {
    width: 100%;
    max-width: 100%;
  }
  .bathrooms-text40 {
    margin-right: 0rem;
  }
  .bathrooms-image19 {
    flex: 0 0 auto;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-halfunit);
    background-image: url("/photos/bathrooms/330390426_749024616622009_946065905556702757_n-1400w.jpg");
    background-repeat: top;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: var(--dl-radius-radius-radius24);
  }
  .bathrooms-image20 {
    flex: 0 0 auto;
    width: calc(100% - 2rem);
    display: none;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    background-image: url("/photos/bathrooms/330390426_749024616622009_946065905556702757_n-1400w.jpg");
    background-repeat: top;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: var(--dl-radius-radius-radius24);
  }
  .bathrooms-max-width3 {
    flex-direction: column;
  }
  .bathrooms-head7 {
    max-width: 100%;
  }
  .bathrooms-image21 {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),ee5370d1-4d8a-4b4d-8f9a-23c600bcec9a;
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .bathrooms-content2 {
    max-width: 100%;
  }
  .bathrooms-max-width4 {
    flex-direction: column;
  }
  .bathrooms-content4 {
    padding: var(--dl-space-space-unit);
  }
  .bathrooms-text73 {
    width: 100%;
  }
}
 
@media(max-width: 479px) {
  .bathrooms-text15 {
    font-size: 2rem;
  }
  .bathrooms-text19 {
    margin-bottom: 0px;
  }
  .bathrooms-text23 {
    margin-bottom: 0px;
  }
  .bathrooms-text27 {
    margin-bottom: 0px;
  }
  .bathrooms-text72 {
    font-size: 2rem;
  }
  .bathrooms-text78 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .bathrooms-text88 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
