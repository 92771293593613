.contact-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.contact-hero-fade {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.contact-hero {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.8) 29.00%,rgba(255, 255, 255, 0) 54.00%),url("/photos/contact/image1%20(1)-min%20(1)%20cropped.jpg");
  background-position: center;
}
 
.contact-max-width1 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1000px;
  align-self: center;
  border-radius: var(--dl-radius-radius-radius2);
  flex-direction: column;
}
 
.contact-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.contact-prehead {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.contact-text12 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  margin-top: -6px;
  margin-left: -3px;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000;
}
 
.contact-text21 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
  display: none;
  text-shadow: 0 0 15px #000000;
}
 
.contact-contact {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.contact-max-width2 {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
 
.contact-contact-page-form-container {
  width: 50%;
  display: flex;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
 
.contact-form {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  flex-direction: column;
}
 
.contact-container12 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-image1 {
  width: 25px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-quarterunit);
}
 
.contact-input1 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-text-input1 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-container13 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-image2 {
  width: 25px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-quarterunit);
}
 
.contact-input2 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-text-input2 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-container14 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-image3 {
  width: 25px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-quarterunit);
}
 
.contact-input3 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-text-input3 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-container15 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-image4 {
  width: 25px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-quarterunit);
}
 
.contact-container16 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-input4 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-text-input4 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-container17 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-input5 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-text-input5 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-input6 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-text-input6 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-container18 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-image5 {
  width: 25px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-quarterunit);
}
 
.contact-input7 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-textarea {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-container19 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-button {
  margin-top: var(--dl-space-space-halfunit);
}
 
.contact-heading-container {
  width: 50%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.contact-photo-container {
  gap: var(--dl-space-space-doubleunit);
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  max-width: 1000px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
 
.contact-column1 {
  gap: var(--dl-space-space-doubleunit);
  flex: 1;
  display: flex;
  position: relative;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.contact-photo-card1 {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-image6 {
  top: 0;
  flex: 1;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: relative;
  max-width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.contact-photo-card2 {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-image7 {
  top: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: relative;
  max-width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.contact-column2 {
  gap: var(--dl-space-space-doubleunit);
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.contact-photo-card3 {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-image8 {
  top: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: relative;
  max-width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.contact-photo-card4 {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-image9 {
  top: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: relative;
  max-width: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.contact-text28 {
  display: inline-block;
}
 
.contact-text29 {
  display: inline-block;
}
 
.contact-text30 {
  display: inline-block;
}
 
.contact-text33 {
  display: inline-block;
}
 
.contact-text34 {
  display: inline-block;
}
 
.contact-text35 {
  display: inline-block;
}
 
.contact-text38 {
  display: inline-block;
}
 
.contact-text39 {
  display: inline-block;
}
 
.contact-text40 {
  display: inline-block;
}
 
.contact-text43 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .contact-text12 {
    font-size: 2.5rem;
  }
  .contact-contact-page-form-container {
    align-items: flex-start;
    flex-direction: column;
  }
  .contact-form {
    width: 100%;
  }
  .contact-container12 {
    width: 100%;
    flex-direction: row;
  }
  .contact-container13 {
    width: 100%;
    flex-direction: row;
  }
  .contact-container14 {
    width: 100%;
    flex-direction: row;
  }
  .contact-container15 {
    width: 100%;
    flex-direction: row;
  }
  .contact-container17 {
    width: 100%;
    flex-direction: row;
  }
  .contact-container18 {
    width: 100%;
    flex-direction: row;
  }
  .contact-photo-container {
    justify-content: center;
  }
}
 
@media(max-width: 767px) {
  .contact-hero {
    align-content: center;
  }
  .contact-text12 {
    font-size: 32px;
  }
  .contact-text13 {
    display: none;
  }
  .contact-text21 {
    display: block;
  }
  .contact-max-width2 {
    align-items: center;
    flex-direction: column;
  }
  .contact-contact-page-form-container {
    width: 100%;
    align-items: center;
  }
  .contact-heading-container {
    width: 100%;
    align-items: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 479px) {
  .contact-container12 {
    align-items: stretch;
  }
  .contact-input1 {
    width: 100%;
  }
  .contact-container13 {
    align-items: stretch;
  }
  .contact-input2 {
    width: 100%;
  }
  .contact-container14 {
    align-items: stretch;
  }
  .contact-input3 {
    width: 100%;
  }
  .contact-container15 {
    align-items: stretch;
  }
  .contact-input4 {
    width: 100%;
  }
  .contact-container17 {
    align-items: stretch;
  }
  .contact-input5 {
    width: 100%;
  }
  .contact-input6 {
    width: 100%;
  }
  .contact-text31 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .contact-text41 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
