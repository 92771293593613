.kitchens-container1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.kitchens-hero-fade-single {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
  justify-content: center;
}
 
.kitchens-hero {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.8) 38.00%,rgba(38, 43, 51, 0.5) 50.00%,rgba(38, 43, 51, 0) 60.00%),url("/photos/kitchens/179279947_363488748424937_4018633155401013782_n%20cropped-1500w.jpg");
  background-position: center;
}
 
.kitchens-content-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
 
.kitchens-max-width1 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.kitchens-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.kitchens-prehead1 {
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000, 0 0 30px #000000, 0 0 4px #000000;
}
 
.kitchens-main-title {
  text-align: left;
}
 
.kitchens-text14 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  max-width: 500px;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.kitchens-showcase {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.kitchens-heading {
  flex: 0 0 auto;
  width: calc(2rem + 450px + 50vw);
  display: flex;
  align-self: flex-start;
  align-items: flex-end;
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.kitchens-prehead2 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.kitchens-text15 {
  text-align: right;
}
 
.kitchens-row1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.kitchens-image10 {
  flex: 0 0 auto;
  width: calc(50% + 100px);
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 50.00%),url("/photos/kitchens/162040081_463948628084164_9141381592977607458_n1-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.kitchens-container2 {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
 
.kitchens-text16 {
  width: 350px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.kitchens-bio1 {
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
 
.kitchens-text19 {
  color: var(--dl-color-gray-900);
}
 
.kitchens-row2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.kitchens-image11 {
  flex: 0 0 auto;
  width: calc(50% + 100px);
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 48.00%),url("/photos/kitchens/218101624_228229749022861_203455337673028463_n1-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.kitchens-container3 {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
 
.kitchens-text20 {
  width: 350px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.kitchens-bio2 {
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
 
.kitchens-text23 {
  color: var(--dl-color-gray-900);
}
 
.kitchens-row3 {
  flex: 0 0 auto;
  width: 100%;
  display: none;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.kitchens-image12 {
  flex: 0 0 auto;
  width: 60%;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/about/img_98531-1500w.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.kitchens-container4 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
 
.kitchens-text24 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.kitchens-title {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.kitchens-text25 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.kitchens-bio3 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.kitchens-row4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.kitchens-image13 {
  flex: 0 0 auto;
  width: calc(50% + 100px);
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 49.00%),url("/photos/kitchens/image4%20cropped-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.kitchens-container5 {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
 
.kitchens-text26 {
  width: 350px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.kitchens-bio4 {
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
 
.kitchens-text29 {
  color: var(--dl-color-gray-900);
}
 
.kitchens-material-grid {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.kitchens-heading-container2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.kitchens-text30 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  letter-spacing: 1px;
}
 
.kitchens-mission-small-cover {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.kitchens-row5 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: flex-start;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.kitchens-item1 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.kitchens-image14 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/kitchens/materials/188091003_856557105072393_4202069016629068525_n1-1000w.jpg");
  background-repeat: top;
  background-position: top;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.kitchens-gradient1 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 50.00%);
}
 
.kitchens-details1 {
  display: flex;
  z-index: 5;
  max-width: 400px;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.kitchens-head1 {
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.kitchens-item2 {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.kitchens-image15 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/kitchens/materials/snapinsta.app_172628707_2942421656036474_6283251894177354657_n_1080%20cropped-1000w.jpg");
  background-repeat: center;
  background-position: right,center;
}
 
.kitchens-details2 {
  display: flex;
  z-index: 5;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.kitchens-head2 {
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.kitchens-row6 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: flex-start;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.kitchens-item3 {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.kitchens-image16 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/kitchens/materials/snapinsta.app_221477968_255241019407362_6621420307584013486_n_1080-1000w.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.kitchens-details3 {
  display: flex;
  z-index: 5;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.kitchens-head3 {
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.kitchens-item4 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.kitchens-image17 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/kitchens/materials/canon_20eos_20rebel_20t6_205184x3456_000354%202-1000w.jpg");
  background-repeat: bottom;
  background-position: bottom;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.kitchens-gradient2 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 48.00%);
}
 
.kitchens-details4 {
  display: flex;
  z-index: 5;
  max-width: 400px;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.kitchens-head4 {
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.kitchens-row7 {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: flex-start;
  aspect-ratio: 3;
  flex-direction: row;
  justify-content: center;
}
 
.kitchens-item5 {
  flex: 2;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.kitchens-image18 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/kitchens/materials/234088729_337637791405509_8405938657056440355_n-1000w.jpg");
  background-repeat: center;
  background-position: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.kitchens-gradient3 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 50.00%);
}
 
.kitchens-details5 {
  display: flex;
  z-index: 5;
  max-width: 400px;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.kitchens-head5 {
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.kitchens-item6 {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.kitchens-image19 {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/kitchens/materials/snapinsta.app_222890014_363345098491836_2374340971904018512_n_1080%20cropped-1000w.jpg");
  background-repeat: center;
  background-position: center;
}
 
.kitchens-details6 {
  display: flex;
  z-index: 5;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
 
.kitchens-head6 {
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.kitchens-trends {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.kitchens-max-width2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.kitchens-image20 {
  flex: 0 0 auto;
  width: 100%;
  height: 500px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.8) 2.00%,rgba(38, 43, 51, 0.8) 97.00%,rgb(38, 43, 51) 100.00%),url("/photos/kitchens/snapinsta.app_129103063_2727137200832021_2582010739529301132_n_1080-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.kitchens-content1 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 600px;
  bottom: 0px;
  height: 400px;
  margin: auto;
  display: flex;
  z-index: 5;
  position: absolute;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
 
.kitchens-prehead4 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.kitchens-text44 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #000000, 0 0 5px #000000;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.kitchens-text47 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.kitchens-text48 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
  margin-bottom: var(--dl-space-space-unit);
}
 
.kitchens-text49 {
  color: var(--dl-color-gray-900);
}
 
.kitchens-text52 {
  color: var(--dl-color-gray-900);
}
 
.kitchens-text54 {
  color: var(--dl-color-gray-900);
}
 
.kitchens-text56 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.kitchens-cta {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
 
.kitchens-max-width3 {
  width: 100%;
  display: flex;
  z-index: 10;
  position: relative;
  align-self: flex-end;
  border-radius: var(--dl-radius-radius-radius2);
  flex-direction: row;
  justify-content: flex-end;
}
 
.kitchens-heading-container3 {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: absolute;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
 
.kitchens-content2 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.kitchens-text57 {
  text-align: center;
}
 
.kitchens-text61 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  width: 500px;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
 
.kitchens-image21 {
  width: 85%;
  height: 500px;
  display: flex;
  overflow: hidden;
  position: relative;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column-reverse;
  justify-content: center;
  background-repeat: top left;
  background-position: top left,bottom;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.kitchens-image22 {
  width: 100%;
  object-fit: cover;
}
 
.kitchens-container6 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.8) 48.00%,rgba(255, 255, 255, 0) 70.00%);
}
 
.kitchens-text66 {
  display: inline-block;
}
 
.kitchens-text67 {
  display: inline-block;
}
 
.kitchens-text68 {
  display: inline-block;
}
 
.kitchens-text71 {
  display: inline-block;
}
 
.kitchens-text72 {
  display: inline-block;
}
 
.kitchens-text73 {
  display: inline-block;
}
 
.kitchens-text76 {
  display: inline-block;
}
 
.kitchens-text77 {
  display: inline-block;
}
 
.kitchens-text78 {
  display: inline-block;
}
 
.kitchens-text81 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .kitchens-heading {
    width: 100%;
  }
  .kitchens-container2 {
    flex: 1;
  }
  .kitchens-text16 {
    width: auto;
  }
  .kitchens-container3 {
    flex: 1;
  }
  .kitchens-text20 {
    width: auto;
  }
  .kitchens-text24 {
    width: 350px;
  }
  .kitchens-container5 {
    flex: 1;
  }
  .kitchens-text26 {
    width: auto;
  }
  .kitchens-text36 {
    height: 80px;
  }
  .kitchens-text37 {
    height: 80px;
  }
  .kitchens-text38 {
    height: 80px;
  }
  .kitchens-text39 {
    height: 80px;
  }
  .kitchens-text40 {
    height: 80px;
  }
}
 
@media(max-width: 767px) {
  .kitchens-hero {
    justify-content: center;
  }
  .kitchens-max-width1 {
    align-items: center;
  }
  .kitchens-showcase {
    padding-right: var(--dl-space-space-unit);
  }
  .kitchens-row1 {
    flex-direction: column;
  }
  .kitchens-image10 {
    width: 100%;
  }
  .kitchens-row2 {
    flex-direction: column;
  }
  .kitchens-image11 {
    width: 100%;
  }
  .kitchens-row3 {
    flex-direction: column;
  }
  .kitchens-image12 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .kitchens-text24 {
    width: 230px;
  }
  .kitchens-row4 {
    flex-direction: column;
  }
  .kitchens-image13 {
    width: 100%;
  }
  .kitchens-material-grid {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .kitchens-heading-container2 {
    align-items: center;
  }
  .kitchens-prehead3 {
    text-align: center;
  }
  .kitchens-row5 {
    aspect-ratio: auto;
  }
  .kitchens-item1 {
    flex: 1;
  }
  .kitchens-details1 {
    max-width: 100%;
    margin-left: 0;
  }
  .kitchens-text36 {
    height: auto;
  }
  .kitchens-details2 {
    max-width: 100%;
    margin-left: 0;
  }
  .kitchens-text37 {
    height: auto;
  }
  .kitchens-row6 {
    aspect-ratio: auto;
  }
  .kitchens-details3 {
    max-width: 100%;
    margin-left: 0;
  }
  .kitchens-text38 {
    height: auto;
  }
  .kitchens-item4 {
    flex: 1;
  }
  .kitchens-details4 {
    max-width: 100%;
    margin-left: 0;
  }
  .kitchens-text39 {
    height: auto;
  }
  .kitchens-row7 {
    aspect-ratio: auto;
  }
  .kitchens-item5 {
    flex: 1;
  }
  .kitchens-details5 {
    max-width: 100%;
    margin-left: 0;
  }
  .kitchens-text40 {
    height: auto;
  }
  .kitchens-details6 {
    max-width: 100%;
    margin-left: 0;
  }
  .kitchens-text43 {
    height: auto;
  }
  .kitchens-max-width2 {
    flex-direction: column;
  }
  .kitchens-image20 {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    margin: auto;
    position: absolute;
  }
  .kitchens-content1 {
    width: 100%;
    height: auto;
    position: static;
    max-width: 100%;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .kitchens-max-width3 {
    align-items: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column-reverse;
  }
  .kitchens-heading-container3 {
    width: 100%;
    position: static;
    margin-top: -100px;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0;
  }
  .kitchens-content2 {
    padding: var(--dl-space-space-unit);
  }
  .kitchens-text61 {
    width: 100%;
  }
  .kitchens-image21 {
    width: 100%;
    height: 300px;
    border-top-right-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
  }
  .kitchens-image22 {
    height: 100%;
  }
  .kitchens-container6 {
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%);
  }
}
 
@media(max-width: 479px) {
  .kitchens-text24 {
    width: 300px;
    margin-bottom: 0px;
  }
  .kitchens-material-grid {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .kitchens-row5 {
    gap: var(--dl-space-space-unit);
  }
  .kitchens-row6 {
    gap: var(--dl-space-space-unit);
  }
  .kitchens-row7 {
    gap: var(--dl-space-space-unit);
  }
  .kitchens-cta {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .kitchens-text57 {
    font-size: 2rem;
  }
  .kitchens-image21 {
    height: 200px;
  }
  .kitchens-text69 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .kitchens-text79 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
