.footer-footer {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  position: relative;
  align-self: end;
  align-items: center;
  justify-self: end;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-key-gunmetal);
}
 
.footer-vertical {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
 
.footer-menu {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.footer-image {
  width: 200px;
  object-fit: cover;
}
 
.footer-links {
  display: none;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
}
 
.footer-container1 {
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  flex-direction: column;
}
 
.footer-link10 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.footer-link11 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.footer-link12 {
  text-decoration: none;
}
 
.footer-container2 {
  display: flex;
  align-items: flex-start;
  margin-left: 100px;
  flex-direction: column;
}
 
.footer-link13 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.footer-link14 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.footer-link15 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.footer-link16 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.footer-follow-container {
  display: flex;
  align-items: center;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
}
 
.footer-text10 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  margin-top: -6px;
  padding-bottom: var(--dl-space-space-quarterunit);
}
 
.footer-icons-container {
  width: 60px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.footer-link18 {
  display: contents;
}
 
.footer-icon1 {
  fill: var(--dl-color-key-iron);
  width: 24px;
  height: 24px;
  text-decoration: none;
}
 
.footer-link19 {
  display: contents;
}
 
.footer-icon3 {
  fill: var(--dl-color-key-iron);
  width: 24px;
  height: 24px;
  text-decoration: none;
}
 
.footer-link20 {
  display: contents;
}
 
.footer-icon5 {
  fill: var(--dl-color-key-iron);
  width: 24px;
  height: 24px;
  display: none;
}
 
.footer-credit {
  width: 100%;
  display: flex;
  max-width: 1000px;
  margin-top: -6px;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
 
.footer-text11 {
  fill: var(--dl-color-gray-600);
  color: var(--dl-color-gray-600);
  font-size: 12px;
  text-decoration: none;
}
 
.footer-text12 {
  color: var(--dl-color-key-mountainmist);
  text-decoration: none;
}
 
.footer-text13 {
  display: inline-block;
}
 
.footer-text16 {
  display: inline-block;
}
 
.footer-text17 {
  display: inline-block;
}
 
.footer-text18 {
  display: inline-block;
}
 
.footer-text19 {
  display: inline-block;
}
 
.footer-text20 {
  display: inline-block;
}
 
.footer-text21 {
  display: inline-block;
}
 
.footer-text24 {
  display: inline-block;
}
 
.footer-text25 {
  display: inline-block;
}
 
.footer-text28 {
  display: inline-block;
}
 

 

 
@media(max-width: 767px) {
  .footer-vertical {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .footer-menu {
    flex-direction: column;
  }
  .footer-links {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .footer-container2 {
    margin-left: 0px;
  }
  .footer-credit {
    flex-direction: column;
  }
}
 
@media(max-width: 479px) {
  .footer-links {
    height: 100%;
    align-items: flex-start;
    margin-left: 50px;
  }
  .footer-link10 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-link11 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-link12 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-link13 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-link14 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-link15 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-link16 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-follow-container {
    margin-left: 0px;
  }
  .footer-text22 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-text26 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
