.contact-form-contact-form {
  width: 100%;
  display: flex;
  position: static;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.contact-form-max-width1 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 750px;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-heading-container {
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.contact-form-prehead {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  align-self: center;
  text-shadow: 0 0 10px #000000;
}
 
.contact-form-text10 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-shadow: 0 0 30px #000000;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.contact-form-text13 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-align: center;
  text-shadow: 0 0 10px #000000;
}
 
.contact-form-text18 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  display: none;
  text-align: center;
}
 
.contact-form-max-width2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-form-form {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  flex-direction: column;
}
 
.contact-form-container1 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-form-image1 {
  width: 25px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-quarterunit);
}
 
.contact-form-input1 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text-input1 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-form-container2 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-form-image2 {
  width: 25px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-quarterunit);
}
 
.contact-form-input2 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text-input2 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-form-container3 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-form-image3 {
  width: 25px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-quarterunit);
}
 
.contact-form-input3 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text-input3 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-form-container4 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-form-image4 {
  width: 25px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-quarterunit);
}
 
.contact-form-container5 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-input4 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text-input4 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-form-container6 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-form-input5 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text-input5 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-form-input6 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text-input6 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-form-container7 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-form-image5 {
  width: 25px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-quarterunit);
}
 
.contact-form-input7 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-textarea {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  width: 100%;
  text-align: left;
  border-radius: var(--dl-radius-radius-radius2);
  background-color: rgba(255, 255, 255, 0);
}
 
.contact-form-container8 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-form-button {
  margin-top: var(--dl-space-space-halfunit);
}
 

 

 
@media(max-width: 991px) {
  .contact-form-max-width1 {
    align-items: flex-start;
    flex-direction: column;
  }
  .contact-form-text13 {
    max-width: 520px;
  }
  .contact-form-text18 {
    max-width: 520px;
  }
  .contact-form-max-width2 {
    align-items: flex-start;
    flex-direction: column;
  }
  .contact-form-form {
    width: 100%;
  }
  .contact-form-container1 {
    width: 100%;
    flex-direction: row;
  }
  .contact-form-container2 {
    width: 100%;
    flex-direction: row;
  }
  .contact-form-container3 {
    width: 100%;
    flex-direction: row;
  }
  .contact-form-container4 {
    width: 100%;
    flex-direction: row;
  }
  .contact-form-container6 {
    width: 100%;
    flex-direction: row;
  }
  .contact-form-container7 {
    width: 100%;
    flex-direction: row;
  }
}
 
@media(max-width: 767px) {
  .contact-form-heading-container {
    align-items: center;
  }
  .contact-form-prehead {
    text-align: center;
  }
  .contact-form-text10 {
    font-size: 32px;
    text-align: center;
  }
  .contact-form-text11 {
    font-size: 32px;
  }
  .contact-form-text13 {
    text-align: center;
  }
  .contact-form-text18 {
    text-align: center;
  }
}
 
@media(max-width: 479px) {
  .contact-form-text13 {
    display: none;
  }
  .contact-form-text18 {
    display: block;
  }
  .contact-form-max-width2 {
    padding: 0px;
  }
  .contact-form-container1 {
    align-items: stretch;
  }
  .contact-form-input1 {
    width: 100%;
  }
  .contact-form-container2 {
    align-items: stretch;
  }
  .contact-form-input2 {
    width: 100%;
  }
  .contact-form-container3 {
    align-items: stretch;
  }
  .contact-form-input3 {
    width: 100%;
  }
  .contact-form-container4 {
    align-items: stretch;
  }
  .contact-form-input4 {
    width: 100%;
  }
  .contact-form-container6 {
    align-items: stretch;
  }
  .contact-form-input5 {
    width: 100%;
  }
  .contact-form-input6 {
    width: 100%;
  }
}
