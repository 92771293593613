.custom-homes-container1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.custom-homes-hero-fade-single {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
  justify-content: center;
}
 
.custom-homes-hero {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.8) 38.00%,rgba(38, 43, 51, 0.5) 50.00%,rgba(38, 43, 51, 0) 60.00%),url("/photos/custom_homes/img_3627%20cropped-1500w.jpg");
  background-position: center;
}
 
.custom-homes-content-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
 
.custom-homes-max-width1 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.custom-homes-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.custom-homes-main-title {
  text-align: left;
}
 
.custom-homes-text14 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  max-width: 500px;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.custom-homes-showcase {
  gap: var(--dl-space-space-tripleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.custom-homes-heading {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.custom-homes-prehead2 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.custom-homes-text17 {
  max-width: 800px;
  text-align: center;
}
 
.custom-homes-row1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.custom-homes-image10 {
  flex: 1;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 75.00%),url("/photos/custom_homes/process/outsite-co-r-lk3sqlibw-unsplash-1400w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.custom-homes-container2 {
  width: 420px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
 
.custom-homes-text21 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.custom-homes-text22 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.custom-homes-bio1 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-row2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.custom-homes-image11 {
  flex: 1;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 75.00%),url("/photos/custom_homes/process/kaleidico-3v8xo5gbusk-unsplash-1400w.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.custom-homes-container3 {
  width: 420px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
 
.custom-homes-text23 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.custom-homes-text24 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.custom-homes-bio2 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-row3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.custom-homes-image12 {
  flex: 1;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 75.00%),url("/photos/custom_homes/process/r-architecture-wddfbanbhl8-unsplash-1400w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.custom-homes-container4 {
  width: 420px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
 
.custom-homes-text25 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.custom-homes-text26 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.custom-homes-bio3 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-row4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.custom-homes-image13 {
  flex: 1;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 75.00%),url("/photos/custom_homes/process/callum-hill-l7v4rwetdb0-unsplash-1400w.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.custom-homes-container5 {
  width: 420px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
 
.custom-homes-text27 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.custom-homes-text28 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.custom-homes-bio4 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-row5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.custom-homes-image14 {
  flex: 1;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 75.00%),url("/photos/custom_homes/process/avel-chuklanov-ib0va6vdqbw-unsplash-1400w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.custom-homes-container6 {
  width: 420px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
 
.custom-homes-text29 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.custom-homes-text30 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.custom-homes-bio5 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-row6 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.custom-homes-image15 {
  flex: 1;
  height: 300px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(255, 255, 255, 0) 75.00%),url("/photos/custom_homes/process/collov-home-design--adgbdtsbzg-unsplash-1400w.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.custom-homes-container7 {
  width: 420px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
 
.custom-homes-text31 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.custom-homes-text32 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 2rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 5px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.custom-homes-bio6 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-grid-features {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.custom-homes-heading-container2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
 
.custom-homes-prehead3 {
  color: var(--dl-color-key-blueribbon);
}
 
.custom-homes-text33 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.custom-homes-bio7 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  width: 600px;
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-mission-small-cover {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-key-gunmetal);
}
 
.custom-homes-item1 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.custom-homes-image16 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/custom_homes/features/jerry-linsen-hhq2cztmwtc-unsplash.jpg");
  background-repeat: center;
  background-position: center;
}
 
.custom-homes-details1 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.custom-homes-head1 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.custom-homes-description1 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-item2 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.custom-homes-image17 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/custom_homes/features/jelmer-assink-gzetjgu3b_k-unsplash.jpg");
  background-repeat: center;
  background-position: right;
}
 
.custom-homes-details2 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.custom-homes-head2 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.custom-homes-description2 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-item3 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.custom-homes-image18 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/custom_homes/features/li-yang-a8iczvtrhpq-unsplash.jpg");
  background-repeat: center;
  background-position: center;
}
 
.custom-homes-details3 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.custom-homes-head3 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.custom-homes-description3 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-item4 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.custom-homes-image19 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/custom_homes/features/javier-balseiro-jdu4yyk5kgs-unsplash.jpg");
  background-repeat: center;
  background-position: bottom,center;
}
 
.custom-homes-details4 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.custom-homes-head4 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.custom-homes-description4 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-item5 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.custom-homes-image20 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/custom_homes/features/cloris-ying-wfjufg3opyo-unsplash.jpg");
  background-repeat: center;
  background-position: center;
}
 
.custom-homes-details5 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.custom-homes-head5 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.custom-homes-description5 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-item6 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.custom-homes-image21 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/custom_homes/features/benjamin-jopen-2sfssudtyia-unsplash.jpg");
  background-repeat: center;
  background-position: right,center;
}
 
.custom-homes-details6 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.custom-homes-head6 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.custom-homes-description6 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.custom-homes-trends {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.custom-homes-max-width2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.custom-homes-image22 {
  flex: 0 0 auto;
  width: 100%;
  height: 500px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.8) 2.00%,rgba(38, 43, 51, 0.8) 97.00%,rgb(38, 43, 51) 100.00%),url("/photos/custom_homes/francesca-tosolini-lldh9jpph2c-unsplash-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.custom-homes-content1 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 700px;
  bottom: 0px;
  height: 400px;
  margin: auto;
  display: flex;
  z-index: 5;
  position: absolute;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: center;
}
 
.custom-homes-prehead4 {
  color: var(--dl-color-key-blueribbon);
}
 
.custom-homes-text38 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #000000, 0 0 5px #000000;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.custom-homes-text42 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.custom-homes-text44 {
  color: var(--dl-color-key-iron);
}
 
.custom-homes-text46 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.custom-homes-text48 {
  color: var(--dl-color-key-iron);
}
 
.custom-homes-cta {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
 
.custom-homes-max-width3 {
  width: 100%;
  display: flex;
  z-index: 10;
  position: relative;
  align-self: flex-end;
  border-radius: var(--dl-radius-radius-radius2);
  flex-direction: row;
  justify-content: flex-end;
}
 
.custom-homes-heading-container3 {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: absolute;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
 
.custom-homes-content2 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.custom-homes-text50 {
  text-align: center;
}
 
.custom-homes-text54 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  width: 500px;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
 
.custom-homes-image23 {
  width: 85%;
  height: 500px;
  display: flex;
  overflow: hidden;
  position: relative;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column-reverse;
  justify-content: center;
  background-repeat: top left;
  background-position: top left,bottom;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.custom-homes-image24 {
  width: 100%;
  object-fit: cover;
}
 
.custom-homes-container8 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.8) 48.00%,rgba(255, 255, 255, 0) 70.00%);
}
 
.custom-homes-text56 {
  display: inline-block;
}
 
.custom-homes-text57 {
  display: inline-block;
}
 
.custom-homes-text58 {
  display: inline-block;
}
 
.custom-homes-text61 {
  display: inline-block;
}
 
.custom-homes-text62 {
  display: inline-block;
}
 
.custom-homes-text63 {
  display: inline-block;
}
 
.custom-homes-text66 {
  display: inline-block;
}
 
.custom-homes-text67 {
  display: inline-block;
}
 
.custom-homes-text68 {
  display: inline-block;
}
 
.custom-homes-text71 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .custom-homes-content1 {
    margin-right: auto;
  }
}
 
@media(max-width: 767px) {
  .custom-homes-hero {
    justify-content: center;
  }
  .custom-homes-max-width1 {
    align-items: center;
  }
  .custom-homes-row1 {
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .custom-homes-image10 {
    width: 90%;
  }
  .custom-homes-container2 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
  }
  .custom-homes-text21 {
    width: 100%;
  }
  .custom-homes-row2 {
    padding: var(--dl-space-space-unit);
    align-items: flex-end;
    flex-direction: column;
  }
  .custom-homes-image11 {
    width: 90%;
  }
  .custom-homes-container3 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
  }
  .custom-homes-text23 {
    width: 100%;
  }
  .custom-homes-row3 {
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .custom-homes-image12 {
    width: 90%;
  }
  .custom-homes-container4 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
  }
  .custom-homes-text25 {
    width: 100%;
  }
  .custom-homes-row4 {
    padding: var(--dl-space-space-unit);
    align-items: flex-end;
    flex-direction: column;
  }
  .custom-homes-image13 {
    width: 90%;
  }
  .custom-homes-container5 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
  }
  .custom-homes-text27 {
    width: 100%;
  }
  .custom-homes-row5 {
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
  }
  .custom-homes-image14 {
    width: 90%;
  }
  .custom-homes-container6 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
  }
  .custom-homes-text29 {
    width: 100%;
  }
  .custom-homes-row6 {
    padding: var(--dl-space-space-unit);
    align-items: flex-end;
    flex-direction: column;
  }
  .custom-homes-image15 {
    width: 90%;
  }
  .custom-homes-container7 {
    width: 90%;
    padding: 0px;
    position: static;
    max-width: 800px;
  }
  .custom-homes-text31 {
    width: 100%;
  }
  .custom-homes-grid-features {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .custom-homes-heading-container2 {
    align-items: center;
  }
  .custom-homes-prehead3 {
    text-align: center;
  }
  .custom-homes-bio7 {
    width: auto;
  }
  .custom-homes-item1 {
    width: 48%;
  }
  .custom-homes-image16 {
    width: 100%;
    background-image: url("/photos/custom_homes/features/jerry-linsen-hhq2cztmwtc-unsplash.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-homes-details1 {
    max-width: 100%;
    margin-left: 0;
  }
  .custom-homes-item2 {
    width: 48%;
  }
  .custom-homes-image17 {
    width: 100%;
    background-image: url("/photos/custom_homes/features/jelmer-assink-gzetjgu3b_k-unsplash.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-homes-details2 {
    max-width: 100%;
    margin-left: 0;
  }
  .custom-homes-item3 {
    width: 48%;
  }
  .custom-homes-image18 {
    width: 100%;
    background-image: url("/photos/custom_homes/features/li-yang-a8iczvtrhpq-unsplash.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-homes-details3 {
    max-width: 100%;
    margin-left: 0;
  }
  .custom-homes-item4 {
    width: 48%;
  }
  .custom-homes-image19 {
    width: 100%;
    background-image: url("/photos/custom_homes/features/javier-balseiro-jdu4yyk5kgs-unsplash.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-homes-details4 {
    max-width: 100%;
    margin-left: 0;
  }
  .custom-homes-item5 {
    width: 48%;
  }
  .custom-homes-image20 {
    width: 100%;
    background-image: url("/photos/custom_homes/features/cloris-ying-wfjufg3opyo-unsplash.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-homes-details5 {
    max-width: 100%;
    margin-left: 0;
  }
  .custom-homes-item6 {
    width: 48%;
  }
  .custom-homes-image21 {
    width: 100%;
    background-image: url("/photos/custom_homes/features/benjamin-jopen-2sfssudtyia-unsplash.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .custom-homes-details6 {
    max-width: 100%;
    margin-left: 0;
  }
  .custom-homes-max-width2 {
    flex-direction: column;
  }
  .custom-homes-image22 {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    margin: auto;
    position: absolute;
  }
  .custom-homes-content1 {
    width: 100%;
    height: auto;
    position: static;
    max-width: 100%;
    padding-top: var(--dl-space-space-quadruple);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    padding-bottom: var(--dl-space-space-quadruple);
  }
  .custom-homes-max-width3 {
    align-items: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column-reverse;
  }
  .custom-homes-heading-container3 {
    width: 100%;
    position: static;
    margin-top: -100px;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0;
  }
  .custom-homes-content2 {
    padding: var(--dl-space-space-unit);
  }
  .custom-homes-text54 {
    width: 100%;
  }
  .custom-homes-image23 {
    width: 100%;
    height: 300px;
    border-top-right-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
  }
  .custom-homes-image24 {
    height: 100%;
  }
  .custom-homes-container8 {
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%);
  }
}
 
@media(max-width: 479px) {
  .custom-homes-text17 {
    font-size: 2rem;
  }
  .custom-homes-text21 {
    margin-bottom: 0px;
  }
  .custom-homes-text23 {
    margin-bottom: 0px;
  }
  .custom-homes-text25 {
    margin-bottom: 0px;
  }
  .custom-homes-text27 {
    margin-bottom: 0px;
  }
  .custom-homes-text29 {
    margin-bottom: 0px;
  }
  .custom-homes-text31 {
    margin-bottom: 0px;
  }
  .custom-homes-grid-features {
    padding-left: var(--dl-space-space-quarterunit);
    padding-right: var(--dl-space-space-quarterunit);
  }
  .custom-homes-text33 {
    font-size: 2rem;
  }
  .custom-homes-cta {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .custom-homes-text50 {
    font-size: 2rem;
  }
  .custom-homes-image23 {
    height: 200px;
  }
  .custom-homes-text59 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .custom-homes-text69 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
