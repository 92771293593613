.about-container10 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.about-banner {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.about-hero {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.9) 7.00%,rgba(38, 43, 51, 0.9) 100.00%),url("/photos/home/process/snapinsta.app_271881287_134163705739032_1045483567002160642_n_1080-min.jpg");
  background-position: top left,center;
}
 
.about-content-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.about-max-width1 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1000px;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.about-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.about-prehead1 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.about-prehead2 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  display: none;
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.about-main-title {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 3.5rem;
  margin-top: -6px;
  text-align: center;
  font-family: "Goldman";
  font-weight: 700;
  line-height: 0.95;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.about-mission {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.about-max-width2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.about-image10 {
  flex: 0 0 auto;
  width: 90%;
  height: 500px;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.8) 48.00%,rgba(255, 255, 255, 0) 79.00%),url("/photos/about/img_98531-1500w.jpg");
  background-position: top left,center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.about-float {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 400px;
  margin: auto;
  display: flex;
  position: absolute;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: column;
}
 
.about-content1 {
  flex: 0 0 auto;
  width: 600px;
  height: 400px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: column;
}
 
.about-prehead3 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.about-text13 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.about-text14 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.about-text17 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000, 0 0 10px #000000aa, 0 0 30px #000000;
}
 
.about-metrics1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  overflow: visible;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row-reverse;
  justify-content: center;
  background-color: var(--dl-color-key-gunmetal);
}
 
.about-img1 {
  flex: 0 0 auto;
  width: 100%;
  height: 105%;
  margin: auto;
  display: flex;
  overflow: hidden;
  position: absolute;
  max-width: 100%;
  align-items: center;
  flex-direction: column;
}
 
.about-image11 {
  width: 100%;
  bottom: 0px;
  position: absolute;
  min-height: 100%;
  object-fit: cover;
}
 
.about-container11 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0.9) 7.00%,rgba(38, 43, 51, 0.9) 89.00%,rgb(38, 43, 51) 98.00%);
}
 
.about-container12 {
  top: 0em;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  z-index: 0;
  position: absolute;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 7.00%,rgba(38, 43, 51, 0) 92.00%,rgb(38, 43, 51) 98.00%);
}
 
.about-max-width3 {
  flex: 1;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: space-between;
}
 
.about-heading-container2 {
  display: flex;
  z-index: 1;
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: column;
}
 
.about-prehead4 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  align-self: flex-start;
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
}
 
.about-text18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 2.8rem;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
  margin-bottom: var(--dl-space-space-quarterunit);
  text-transform: uppercase;
  text-decoration: none;
}
 
.about-text22 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
}
 
.about-metrics2 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  padding-right: 30rem;
  flex-direction: column;
}
 
.about-metrics-container {
  gap: var(--dl-space-space-tripleunit);
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}
 
.about-container13 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.about-text23 {
  color: var(--dl-color-key-gunmetal);
  font-style: normal;
  font-family: "Bai Jamjuree";
  font-weight: 600;
}
 
.about-text24 {
  fill: var(--dl-color-key-gunmetal);
  color: var(--dl-color-key-gunmetal);
  font-size: 60px;
  font-style: normal;
  margin-top: -20px;
  font-family: "Bai Jamjuree";
  font-weight: 600;
  margin-left: -5px;
  text-shadow: 3px 3px 5px #000;
  margin-bottom: -20px;
  -webkit-text-stroke-color: var(--dl-color-key-blueribbon);
  -webkit-text-stroke-width: 2px;
}
 
.about-text25 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  align-self: flex-end;
  margin-left: -15px;
  text-shadow: 0 0 10px #000000;
}
 
.about-container14 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.about-text29 {
  fill: var(--dl-color-key-gunmetal);
  color: var(--dl-color-key-gunmetal);
  font-style: normal;
  font-family: "Bai Jamjuree";
  font-weight: 600;
  text-shadow: 3px 3px 5px #000;
  -webkit-text-stroke-color: var(--dl-color-key-blueribbon);
  -webkit-text-stroke-width: 2px;
}
 
.about-text30 {
  fill: var(--dl-color-key-gunmetal);
  color: var(--dl-color-key-gunmetal);
  font-size: 60px;
  font-style: normal;
  margin-top: -20px;
  font-family: "Bai Jamjuree";
  font-weight: 600;
  margin-left: -5px;
  text-shadow: 3px 3px 5px #000;
  margin-bottom: -20px;
  -webkit-text-stroke-color: var(--dl-color-key-blueribbon);
  -webkit-text-stroke-width: 2px;
}
 
.about-text31 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  align-self: flex-end;
  margin-left: -15px;
  text-shadow: 0 0 10px #000000;
}
 
.about-container15 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.about-text35 {
  color: var(--dl-color-key-gunmetal);
  font-style: normal;
  font-family: "Bai Jamjuree";
  font-weight: 600;
}
 
.about-text36 {
  fill: var(--dl-color-key-gunmetal);
  color: var(--dl-color-key-gunmetal);
  font-size: 60px;
  font-style: normal;
  margin-top: -20px;
  font-family: "Bai Jamjuree";
  font-weight: 600;
  margin-left: -5px;
  text-shadow: 3px 3px 5px #000;
  margin-bottom: -20px;
  -webkit-text-stroke-color: var(--dl-color-key-blueribbon);
  -webkit-text-stroke-width: 2px;
}
 
.about-text37 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  align-self: flex-end;
  margin-left: -15px;
  text-shadow: 0 0 10px #000000;
}
 
.about-bg-container {
  gap: var(--dl-space-space-fiveunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: -2rem;
  align-items: flex-start;
  padding-top: 2rem;
  margin-bottom: -2rem;
  flex-direction: column;
  padding-bottom: 2rem;
}
 
.about-bg {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  opacity: 0.04;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-size: 900px;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 6.00%,rgba(38, 43, 51, 0) 89.00%,rgb(38, 43, 51) 100.00%),url("/photos/about/logo%20tiled%20special-1500w.png");
  background-repeat: repeat;
  background-position: center;
}
 
.about-team {
  width: 100%;
  display: flex;
  overflow: visible;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
}
 
.about-heading-container3 {
  display: flex;
  z-index: 1;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.about-prehead5 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000, 0 0 15px #000000;
  margin-bottom: -6px;
}
 
.about-text41 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-align: center;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
}
 
.about-team-container {
  gap: var(--dl-space-space-doubleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  flex-direction: row;
  justify-content: center;
}
 
.about-collumn {
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
 
.about-person1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
 
.about-image12 {
  width: 400px;
  object-fit: cover;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.about-text42 {
  width: 400px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-bottom: 200px;
  flex-direction: column;
}
 
.about-title1 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.about-bio1 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
 
.about-person2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  margin-top: -200px;
  align-items: flex-start;
  flex-direction: row;
}
 
.about-text44 {
  width: 400px;
  display: flex;
  align-self: center;
  margin-top: 200px;
  align-items: flex-start;
  flex-direction: column;
}
 
.about-title2 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.55;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.about-bio2 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
 
.about-image13 {
  width: 400px;
  object-fit: cover;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.about-values {
  width: 100%;
  display: flex;
  overflow: visible;
  position: relative;
  margin-top: -2rem;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
}
 
.about-image14 {
  top: 50px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: none;
  opacity: 0.35;
  z-index: 0;
  position: absolute;
  max-width: 1000px;
  object-fit: cover;
}
 
.about-heading-container4 {
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.about-prehead6 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000;
  margin-bottom: -6px;
}
 
.about-text46 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  text-align: center;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
}
 
.about-value-container {
  gap: var(--dl-space-space-tripleunit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  flex-direction: row;
  justify-content: center;
}
 
.about-container16 {
  flex: 0 0 auto;
  width: 300px;
  height: 300px;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: relative;
  box-shadow: 0px 10px 20px 0px #000000;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.about-text47 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  z-index: 100;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  margin-bottom: var(--dl-space-space-halfunit);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
 
.about-text48 {
  z-index: 100;
}
 
.about-image15 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  opacity: 0.1;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: absolute;
  object-fit: cover;
}
 
.about-container17 {
  flex: 0 0 auto;
  width: 300px;
  height: 300px;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: relative;
  box-shadow: 0px 10px 20px 0px #000000;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.about-text52 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  z-index: 100;
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  margin-bottom: var(--dl-space-space-halfunit);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
 
.about-text53 {
  z-index: 100;
}
 
.about-image16 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  opacity: 0.1;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: absolute;
  object-fit: cover;
}
 
.about-container18 {
  flex: 0 0 auto;
  width: 300px;
  height: 300px;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: relative;
  box-shadow: 0px 10px 20px 0px #000000;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.about-text57 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  margin-bottom: var(--dl-space-space-halfunit);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 00px;
}
 
.about-image17 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  opacity: 0.1;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: absolute;
  object-fit: cover;
}
 
.about-text58 {
  z-index: 100;
}
 
.about-grid-team {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
}
 
.about-heading-container5 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
 
.about-prehead7 {
  color: var(--dl-color-key-blueribbon);
}
 
.about-text64 {
  text-align: center;
}
 
.about-text65 {
  color: var(--dl-color-gray-900);
  line-height: 1;
  letter-spacing: -1px;
}
 
.about-bio3 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  font-size: 16px;
  max-width: 600px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.about-mission-small-cover {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.about-item1 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.about-image18 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/custom_homes/team/daniel-mccullough-htblqdxfg9k-unsplash.jpg");
  background-repeat: center;
  background-position: center;
}
 
.about-details1 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.about-head1 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.about-description1 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.about-item2 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.about-image19 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/custom_homes/team/thisisengineering-j0kqfj1m0lc-unsplash1.jpg");
  background-repeat: center;
  background-position: top lefttop left,center;
}
 
.about-details2 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.about-head2 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.about-description2 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.about-item3 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.about-image20 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/custom_homes/team/aaron-huber-g7se2s4lab4-unsplash.jpg");
  background-repeat: center;
  background-position: center;
}
 
.about-details3 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.about-head3 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.about-description3 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.about-item4 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.about-image21 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/custom_homes/team/gus-ruballo-h5qncljuia8-unsplash.jpg");
  background-repeat: center;
  background-position: bottom,center;
}
 
.about-details4 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.about-head4 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.about-description4 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.about-item5 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.about-image22 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/about/mandy-choi-j5k2tjaelfg-unsplash.jpg");
  background-repeat: center;
  background-position: top;
}
 
.about-details5 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.about-head5 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.about-description5 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.about-item6 {
  width: 32%;
  display: flex;
  position: relative;
  align-items: flex-start;
  aspect-ratio: 1;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.about-image23 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  background-size: cover;
  background-image: url("/photos/custom_homes/team/glenn-carstens-peters-rlw-uc03gwc-unsplash.jpg");
  background-repeat: center;
  background-position: right,center;
}
 
.about-details6 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 5;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.about-head6 {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
}
 
.about-description6 {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.about-cta {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
 
.about-max-width4 {
  width: 100%;
  display: flex;
  z-index: 10;
  position: relative;
  align-self: flex-end;
  border-radius: var(--dl-radius-radius-radius2);
  flex-direction: row;
  justify-content: flex-end;
}
 
.about-heading-container6 {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  z-index: 1;
  position: absolute;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
 
.about-content2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.about-text72 {
  fill: var(--dl-color-gray-700);
  color: var(--dl-color-gray-700);
  width: 500px;
  text-align: left;
  margin-bottom: var(--dl-space-space-unit);
}
 
.about-image24 {
  width: 85%;
  height: 500px;
  display: flex;
  overflow: hidden;
  position: relative;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column-reverse;
  justify-content: center;
  background-repeat: top left;
  background-position: top left,bottom;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.about-image25 {
  width: 100%;
  object-fit: cover;
}
 
.about-container19 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.8) 47.00%,rgba(255, 255, 255, 0) 72.00%);
}
 
.about-text74 {
  display: inline-block;
}
 
.about-text75 {
  display: inline-block;
}
 
.about-text76 {
  display: inline-block;
}
 
.about-text79 {
  display: inline-block;
}
 
.about-text80 {
  display: inline-block;
}
 
.about-text81 {
  display: inline-block;
}
 
.about-text84 {
  display: inline-block;
}
 
.about-text85 {
  display: inline-block;
}
 
.about-text86 {
  display: inline-block;
}
 
.about-text89 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .about-max-width3 {
    flex-direction: column;
  }
  .about-heading-container2 {
    width: 450px;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .about-metrics2 {
    align-self: center;
  }
  .about-metrics-container {
    justify-content: center;
  }
  .about-team {
    padding-left: 0px;
    padding-right: 0px;
  }
  .about-team-container {
    gap: 0;
    flex-direction: column;
  }
  .about-image12 {
    width: 350px;
  }
  .about-text42 {
    width: 350px;
  }
  .about-text44 {
    width: 350px;
  }
  .about-image13 {
    width: 350px;
  }
  .about-image20 {
    background-image: url("/photos/custom_homes/team/aaron-huber-g7se2s4lab4-unsplash.jpg");
  }
}
 
@media(max-width: 767px) {
  .about-hero {
    justify-content: center;
  }
  .about-max-width1 {
    align-items: center;
  }
  .about-max-width2 {
    flex-direction: column;
  }
  .about-image10 {
    width: 100%;
    background-image: url("/photos/about/img_98531-1500w.jpg");
    background-position: top left,center;
    border-top-left-radius: var(--dl-radius-radius-radius24);
    border-bottom-right-radius: 0;
  }
  .about-float {
    width: 100%;
    height: auto;
    position: relative;
    max-width: 600px;
    margin-top: -10rem;
  }
  .about-content1 {
    max-width: 100%;
  }
  .about-metrics1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-prehead4 {
    text-align: center;
  }
  .about-heading-container3 {
    align-items: center;
  }
  .about-prehead5 {
    text-align: center;
  }
  .about-team-container {
    padding: 0;
  }
  .about-image12 {
    width: 250px;
  }
  .about-text42 {
    width: 230px;
  }
  .about-person2 {
    margin-top: -200px;
  }
  .about-text44 {
    width: 230px;
    margin-top: 50px;
  }
  .about-image13 {
    width: 250px;
  }
  .about-values {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-heading-container4 {
    align-items: center;
  }
  .about-prehead6 {
    text-align: center;
  }
  .about-grid-team {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-heading-container5 {
    align-items: center;
  }
  .about-prehead7 {
    text-align: center;
  }
  .about-item1 {
    width: 49%;
  }
  .about-image18 {
    width: 100%;
  }
  .about-details1 {
    max-width: 100%;
    margin-left: 0;
  }
  .about-item2 {
    width: 49%;
  }
  .about-image19 {
    width: 100%;
  }
  .about-details2 {
    max-width: 100%;
    margin-left: 0;
  }
  .about-item3 {
    width: 49%;
  }
  .about-image20 {
    width: 100%;
  }
  .about-details3 {
    max-width: 100%;
    margin-left: 0;
  }
  .about-item4 {
    width: 49%;
  }
  .about-image21 {
    width: 100%;
    background-image: url("/photos/custom_homes/team/gus-ruballo-h5qncljuia8-unsplash.jpg");
    background-repeat: center;
  }
  .about-details4 {
    max-width: 100%;
    margin-left: 0;
  }
  .about-item5 {
    width: 49%;
  }
  .about-image22 {
    width: 100%;
    background-image: url("/photos/about/mandy-choi-j5k2tjaelfg-unsplash.jpg");
  }
  .about-details5 {
    max-width: 100%;
    margin-left: 0;
  }
  .about-item6 {
    width: 49%;
  }
  .about-image23 {
    width: 100%;
    background-image: url("/photos/custom_homes/team/glenn-carstens-peters-rlw-uc03gwc-unsplash.jpg");
  }
  .about-details6 {
    max-width: 100%;
    margin-left: 0;
  }
  .about-max-width4 {
    align-items: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column-reverse;
  }
  .about-heading-container6 {
    width: 100%;
    position: static;
    margin-top: -100px;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0;
  }
  .about-content2 {
    padding: var(--dl-space-space-unit);
  }
  .about-text72 {
    width: 100%;
  }
  .about-image24 {
    width: 100%;
    height: 300px;
    border-top-right-radius: var(--dl-radius-radius-radius24);
    border-bottom-left-radius: 0;
  }
  .about-image25 {
    height: 100%;
  }
  .about-container19 {
    background-image: linear-gradient(0deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.85) 26.00%,rgba(38, 43, 51, 0.5) 58.00%,rgba(255, 255, 255, 0) 100.00%);
  }
}
 
@media(max-width: 479px) {
  .about-content1 {
    height: 500px;
  }
  .about-heading-container2 {
    width: 100vw;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-text18 {
    font-size: 2rem;
  }
  .about-metrics2 {
    padding-right: 0px;
  }
  .about-metrics-container {
    align-self: center;
    justify-content: center;
  }
  .about-text24 {
    font-size: 35px;
    margin-top: -10px;
  }
  .about-text25 {
    margin-left: 5px;
    margin-bottom: 0px;
  }
  .about-text30 {
    font-size: 35px;
    margin-top: -10px;
  }
  .about-text31 {
    margin-left: 5px;
    margin-bottom: 0px;
  }
  .about-text36 {
    font-size: 35px;
    margin-top: -10px;
  }
  .about-text37 {
    margin-left: 5px;
    margin-bottom: 0px;
  }
  .about-text41 {
    font-size: 2rem;
  }
  .about-person1 {
    flex-direction: column;
  }
  .about-image12 {
    width: 300px;
  }
  .about-text42 {
    width: 300px;
    margin-bottom: 0px;
  }
  .about-person2 {
    margin-top: 0px;
    flex-direction: column-reverse;
  }
  .about-text44 {
    width: 300px;
    margin-top: 0px;
  }
  .about-image13 {
    width: 300px;
  }
  .about-text46 {
    font-size: 2rem;
  }
  .about-grid-team {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .about-details1 {
    padding: var(--dl-space-space-quarterunit);
  }
  .about-details2 {
    padding: var(--dl-space-space-quarterunit);
  }
  .about-details3 {
    padding: var(--dl-space-space-quarterunit);
  }
  .about-details4 {
    padding: var(--dl-space-space-quarterunit);
  }
  .about-details5 {
    padding: var(--dl-space-space-quarterunit);
  }
  .about-details6 {
    padding: var(--dl-space-space-quarterunit);
  }
  .about-cta {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .about-text71 {
    font-size: 2rem;
  }
  .about-image24 {
    height: 200px;
  }
  .about-text77 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .about-text87 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
