.home-test-container10 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-key-gunmetal);
}
 
.home-test-hero-fade-center {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.home-test-hero {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  flex-direction: row;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 0.00%,rgba(255, 255, 255, 0) 71.00%),linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(255, 255, 255, 0) 3.00%),url("/photos/home/638f7ce82168a403dfdb20fc%20cropped.jpg");
  background-position: center;
}
 
.home-test-content-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
 
.home-test-max-width1 {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1000px;
  align-self: center;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10rem;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.home-test-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: -10rem;
  flex-direction: column;
}
 
.home-test-prehead1 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-prehead2 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  display: none;
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 5px #000000, 0 0 3px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-main-title {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  margin-top: -6px;
  text-shadow: 0 0 30px #000000, 0 0 10px #000000, 0 0 15px #000000;
}
 
.home-test-text103 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  padding-top: var(--dl-space-space-halfunit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.home-test-text109 {
  fill: var(--dl-color-gray-800);
  color: var(--dl-color-gray-800);
  display: none;
  padding-top: var(--dl-space-space-unit);
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000;
  padding-bottom: var(--dl-space-space-unit);
}
 
.home-test-link {
  margin-top: var(--dl-space-space-halfunit);
}
 
.home-test-bathroom-value {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-quadruple);
  flex-direction: row;
  background-size: cover;
  justify-content: center;
}
 
.home-test-photo-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.05) 53.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/snapinsta.app_274985307_1167788940695309_6054238171269608400_n_1080-min-1500w.jpg");
  background-position: top left,center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-max-width2 {
  gap: 48px;
  width: 90%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
 
.home-test-column1 {
  gap: 24px;
  width: 60%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-test-heading-container2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-test-text111 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  align-self: center;
  text-align: center;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
}
 
.home-test-text116 {
  color: var(--dl-color-gray-800);
  max-width: 520px;
  text-align: center;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
}
 
.home-test-kitchen-value {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-right: var(--dl-space-space-quadruple);
  flex-direction: row;
  background-size: cover;
  justify-content: center;
}
 
.home-test-photo-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(90deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.05) 53.00%,rgba(255, 255, 255, 0) 100.00%),url("/photos/home/218101624_228229749022861_203455337673028463_n.jpg");
  background-position: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.home-test-max-width3 {
  gap: 48px;
  width: 90%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
 
.home-test-column2 {
  gap: 24px;
  width: 60%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.home-test-heading-container3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-test-text117 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  align-self: center;
  text-align: center;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000, 0 0 10px #00000088;
}
 
.home-test-text121 {
  color: var(--dl-color-gray-800);
  max-width: 520px;
  text-align: center;
  text-shadow: 0 0 15px #000000, 0 0 5px #00000088, 0 0 10px #000000, 0 0 3px #000000;
}
 
.home-test-remodel-types {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.home-test-heading-container4 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-prehead3 {
  fill: var(--dl-color-key-blueribbon);
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000;
}
 
.home-test-text122 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.home-test-types {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.home-test-bathrooms {
  width: 24%;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
}
 
.home-test-image10 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.home-test-container11 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-test-text126 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-style: normal;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1.15;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.home-test-kitchens {
  width: 24%;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
}
 
.home-test-image11 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.home-test-container13 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-test-text127 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.home-test-renovations {
  width: 24%;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
}
 
.home-test-image12 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.home-test-container15 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-test-text128 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  text-align: center;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.home-test-additions {
  width: 24%;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  aspect-ratio: .75;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
}
 
.home-test-image13 {
  opacity: 0.5;
  min-width: 100%;
  align-self: center;
  min-height: 100%;
  object-fit: cover;
  justify-self: center;
}
 
.home-test-container17 {
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-test-text132 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 1.8rem;
  text-align: center;
  font-family: "Goldman";
  font-weight: 400;
  line-height: 1;
  text-shadow: 0 0 30px #000000, 0 0 15px #000000;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: none;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.home-test-bg1 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  opacity: 0.04;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-size: 900px;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 20.00%,rgba(38, 43, 51, 0) 80.00%,rgb(38, 43, 51) 100.00%),url("/photos/about/logo%20tiled-1500w.png");
  background-position: center;
}
 
.home-test-project-highlight {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
  background-color: var(--dl-color-key-gunmetal);
}
 
.home-test-bg2 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  opacity: 0.04;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-size: 900px;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 19.00%,rgba(38, 43, 51, 0) 81.00%,rgb(38, 43, 51) 100.00%),url("/photos/about/logo%20tiled%20special-1500w.png");
  background-position: center;
}
 
.home-test-heading-container5 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-prehead4 {
  color: var(--dl-color-key-blueribbon);
  text-shadow: 0 0 10px #000000;
}
 
.home-test-text136 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.home-test-project0 {
  width: 100%;
  display: flex;
  max-width: 1000px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
 
.home-test-photo {
  width: 70%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
 
.home-test-image14 {
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
  -webkit-mask-image: linear-gradient(90deg, black 53.00%,rgba(255, 255, 255, 0) 100.00%);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-container19 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: none;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(270deg, rgb(38, 43, 51) 1.00%,rgba(38, 43, 51, 0.05) 53.00%,rgba(255, 255, 255, 0) 100.00%);
}
 
.home-test-details {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  z-index: 5;
  position: relative;
  align-items: stretch;
  margin-left: -5rem;
  flex-direction: column;
  justify-content: center;
}
 
.home-test-head {
  fill: var(--dl-color-key-iron);
  color: var(--dl-color-key-iron);
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.home-test-location {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text141 {
  color: var(--dl-color-key-iron);
}
 
.home-test-description {
  color: var(--dl-color-key-mountainmist);
  font-size: 16px;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000, 0 0 5px #000000aa;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-container20 {
  flex: 0 0 auto;
  left: -4px;
  width: 2px;
  height: 90%;
  display: flex;
  position: absolute;
  box-shadow: 0 0 10px #000000;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-blueribbon);
}
 
.home-test-project-selection {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.home-test-image0 {
  width: 18%;
  cursor: pointer;
  opacity: 0.35;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.home-test-image15 {
  width: 18%;
  cursor: pointer;
  opacity: 0.35;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.home-test-image2 {
  width: 18%;
  cursor: pointer;
  opacity: 0.35;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.home-test-image3 {
  width: 18%;
  cursor: pointer;
  opacity: 0.35;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.home-test-image4 {
  width: 18%;
  cursor: pointer;
  opacity: 0.35;
  object-fit: cover;
  aspect-ratio: 1.5;
  border-radius: var(--dl-radius-radius-radius24);
}
 
.home-test-client-process-bubbles1 {
  width: 100%;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: -2rem;
  padding-top: 2rem;
  margin-bottom: -2rem;
  padding-bottom: 2rem;
  justify-content: center;
}
 
.home-test-bg3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
}
 
.home-test-max-width4 {
  gap: 32px;
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  max-width: 1000px;
  align-items: center;
  margin-bottom: var(--dl-space-space-tenunits);
  flex-direction: column;
}
 
.home-test-heading-container6 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.home-test-prehead5 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  align-self: center;
  text-align: center;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text142 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  align-self: center;
  margin-top: -6px;
  text-align: center;
  text-shadow: 0 0 30px #000000;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.home-test-text143 {
  color: var(--dl-color-gray-700);
  align-self: center;
  text-align: center;
}
 
.home-test-text147 {
  color: var(--dl-color-gray-700);
  display: none;
  align-self: center;
  text-align: center;
}
 
.home-test-timeline-container1 {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
 
.home-test-step110 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container21 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-progress010 {
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-arrow10 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-test-container22 {
  flex: 0 0 auto;
  width: 15px;
  bottom: 99px;
  height: 3px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-mountainmist);
}
 
.home-test-text150 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
  right: 10px;
  bottom: 88px;
  display: none;
  position: absolute;
}
 
.home-test-container23 {
  top: 18px;
  left: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-container24 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container25 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
 
.home-test-image16 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text153 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text154 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text160 {
  color: var(--dl-color-gray-800);
  font-size: 16px;
}
 
.home-test-text161 {
  color: var(--dl-color-gray-800);
  font-size: 16px;
}
 
.home-test-text164 {
  color: var(--dl-color-gray-800);
  font-size: 16px;
}
 
.home-test-text167 {
  color: var(--dl-color-gray-800);
  font-size: 16px;
}
 
.home-test-container26 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image17 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-img310 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/1661766222233-e617a1180fa2.png");
  background-position: top left,bottom right;
}
 
.home-test-step111 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container27 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-progress011 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-arrow11 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-test-container28 {
  top: 18px;
  right: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-container29 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container30 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-test-image18 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text170 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text171 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text177 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text180 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text183 {
  color: var(--dl-color-gray-800);
}
 
.home-test-container31 {
  top: -25px;
  flex: 0 0 auto;
  left: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-end;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.home-test-image19 {
  width: 20px;
  object-fit: cover;
  margin-right: -15px;
}
 
.home-test-container32 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -22px;
  margin: auto;
  display: none;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image20 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-img311 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/photo-1676760961225-79bca42420ba.png");
  background-position: top left,center;
}
 
.home-test-step112 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container33 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-progress012 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-arrow12 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-test-container34 {
  top: 18px;
  left: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-container35 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container36 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-test-image21 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text186 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text187 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text193 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text196 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text199 {
  color: var(--dl-color-gray-800);
}
 
.home-test-container37 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image22 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-img312 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/kaleidico-7lryofj0h9s-unsplash.jpg");
  background-position: top left,center;
}
 
.home-test-step113 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container38 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-progress013 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-arrow13 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-test-container39 {
  top: 18px;
  right: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-container40 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container41 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-test-image23 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text202 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text203 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text209 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text212 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text215 {
  color: var(--dl-color-gray-800);
}
 
.home-test-container42 {
  top: -25px;
  flex: 0 0 auto;
  left: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-end;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.home-test-image24 {
  width: 20px;
  object-fit: cover;
  margin-right: -15px;
}
 
.home-test-container43 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -22px;
  margin: auto;
  display: none;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image25 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-img313 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/pedro-miranda-3qzmbrvceyq-unsplash.jpg");
  background-position: top left,center;
}
 
.home-test-step114 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container44 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-progress014 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-arrow14 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-test-container45 {
  top: 18px;
  left: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-img314 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/snapinsta.app_271881287_134163705739032_1045483567002160642_n_1080-min.jpg");
  background-position: top left,center;
}
 
.home-test-container46 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container47 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-test-image26 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text218 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text219 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text227 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text230 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text233 {
  color: var(--dl-color-gray-800);
}
 
.home-test-container48 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image27 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-step115 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container49 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-progress015 {
  width: 2px;
  height: 150px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-key-mountainmist);
}
 
.home-test-container50 {
  flex: 0 0 auto;
  width: 15px;
  bottom: -2px;
  height: 2px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-mountainmist);
}
 
.home-test-text236 {
  top: 135px;
  fill: var(--dl-color-key-mountainmist);
  left: 10px;
  color: var(--dl-color-key-mountainmist);
  display: none;
  position: absolute;
}
 
.home-test-container51 {
  top: 18px;
  right: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-container52 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container53 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-test-image28 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text239 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text240 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text246 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text249 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text252 {
  color: var(--dl-color-gray-800);
}
 
.home-test-container54 {
  top: -25px;
  flex: 0 0 auto;
  left: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-end;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.home-test-image29 {
  width: 20px;
  object-fit: cover;
  margin-right: -15px;
}
 
.home-test-container55 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -22px;
  margin: auto;
  display: none;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image30 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-img315 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/638f7ce82168a403dfdb20fc%20cropped.jpg");
  background-position: top left,center;
}
 
.home-test-bg4 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: none;
  opacity: 0.04;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-size: 900px;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 6.00%,rgba(38, 43, 51, 0) 89.00%,rgb(38, 43, 51) 100.00%),url("/photos/about/logo%20tiled%20special-1500w.png");
  background-position: center;
}
 
.home-test-bg5 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: flex;
  opacity: 0.04;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-size: 900px;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 12.00%,rgba(38, 43, 51, 0) 87.00%,rgb(38, 43, 51) 100.00%),url("/photos/about/logo%20tiled%20special-1500w.png");
  background-position: center;
}
 
.home-test-client-process-bubbles2 {
  width: 100%;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: -2rem;
  padding-top: 2rem;
  margin-bottom: -2rem;
  padding-bottom: 2rem;
  justify-content: center;
}
 
.home-test-bg6 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
}
 
.home-test-max-width5 {
  gap: 32px;
  width: 100%;
  display: flex;
  z-index: 1;
  position: relative;
  max-width: 1000px;
  align-items: center;
  margin-bottom: var(--dl-space-space-tenunits);
  flex-direction: column;
}
 
.home-test-heading-container7 {
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
 
.home-test-prehead6 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  align-self: center;
  text-align: center;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text255 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  align-self: center;
  margin-top: -6px;
  text-align: center;
  text-shadow: 0 0 30px #000000;
  margin-bottom: var(--dl-space-space-quarterunit);
}
 
.home-test-text256 {
  color: var(--dl-color-gray-700);
  align-self: center;
  text-align: center;
}
 
.home-test-text260 {
  color: var(--dl-color-gray-700);
  display: none;
  align-self: center;
  text-align: center;
}
 
.home-test-timeline-container2 {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
 
.home-test-step116 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container56 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-progress016 {
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-arrow15 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-test-container57 {
  flex: 0 0 auto;
  width: 15px;
  bottom: 99px;
  height: 3px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-mountainmist);
}
 
.home-test-text263 {
  fill: var(--dl-color-key-mountainmist);
  color: var(--dl-color-key-mountainmist);
  right: 10px;
  bottom: 88px;
  display: none;
  position: absolute;
}
 
.home-test-container58 {
  top: 18px;
  left: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-container59 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container60 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
 
.home-test-image31 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text266 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text267 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text273 {
  color: var(--dl-color-gray-800);
  font-size: 16px;
}
 
.home-test-text274 {
  color: var(--dl-color-gray-800);
  font-size: 16px;
}
 
.home-test-text277 {
  color: var(--dl-color-gray-800);
  font-size: 16px;
}
 
.home-test-text280 {
  color: var(--dl-color-gray-800);
  font-size: 16px;
}
 
.home-test-container61 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image32 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-img316 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/1661766222233-e617a1180fa2.png");
  background-position: top left,bottom right;
}
 
.home-test-step117 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container62 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-progress017 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-arrow16 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-test-container63 {
  top: 18px;
  right: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-container64 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container65 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-test-image33 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text283 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text284 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text290 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text293 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text296 {
  color: var(--dl-color-gray-800);
}
 
.home-test-container66 {
  top: -25px;
  flex: 0 0 auto;
  left: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-end;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.home-test-image34 {
  width: 20px;
  object-fit: cover;
  margin-right: -15px;
}
 
.home-test-container67 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -22px;
  margin: auto;
  display: none;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image35 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-img317 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/photo-1676760961225-79bca42420ba.png");
  background-position: top left,center;
}
 
.home-test-step118 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container68 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-progress018 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-arrow17 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-test-container69 {
  top: 18px;
  left: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-container70 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container71 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-test-image36 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text299 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text300 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text306 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text309 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text312 {
  color: var(--dl-color-gray-800);
}
 
.home-test-container72 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image37 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-img318 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/kaleidico-7lryofj0h9s-unsplash.jpg");
  background-position: top left,center;
}
 
.home-test-step119 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container73 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-progress019 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-arrow18 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-test-container74 {
  top: 18px;
  right: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-container75 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container76 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-test-image38 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text315 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text316 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text322 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text325 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text328 {
  color: var(--dl-color-gray-800);
}
 
.home-test-container77 {
  top: -25px;
  flex: 0 0 auto;
  left: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-end;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.home-test-image39 {
  width: 20px;
  object-fit: cover;
  margin-right: -15px;
}
 
.home-test-container78 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -22px;
  margin: auto;
  display: none;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image40 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-img319 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/pedro-miranda-3qzmbrvceyq-unsplash.jpg");
  background-position: top left,center;
}
 
.home-test-step120 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container79 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-progress020 {
  height: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: flex-end;
}
 
.home-test-arrow19 {
  width: 20px;
  z-index: 100;
  position: absolute;
  margin-top: -10px;
  object-fit: cover;
  margin-left: -20px;
  margin-right: -20px;
}
 
.home-test-container80 {
  top: 18px;
  left: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-img320 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/process/snapinsta.app_271881287_134163705739032_1045483567002160642_n_1080-min.jpg");
  background-position: top left,center;
}
 
.home-test-container81 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container82 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-test-image41 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text331 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text332 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text340 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text343 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text346 {
  color: var(--dl-color-gray-800);
}
 
.home-test-container83 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image42 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-step121 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
 
.home-test-container84 {
  flex: 0 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-test-progress021 {
  width: 2px;
  height: 150px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-key-mountainmist);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-key-mountainmist);
}
 
.home-test-container85 {
  flex: 0 0 auto;
  width: 15px;
  bottom: -2px;
  height: 2px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-key-mountainmist);
}
 
.home-test-text349 {
  top: 135px;
  fill: var(--dl-color-key-mountainmist);
  left: 10px;
  color: var(--dl-color-key-mountainmist);
  display: none;
  position: absolute;
}
 
.home-test-container86 {
  top: 18px;
  right: 64px;
  width: min(520px, calc(50vw - 100px));
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-test-container87 {
  flex: 0 0 auto;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
 
.home-test-container88 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: -1px;
  align-items: center;
  flex-direction: row;
}
 
.home-test-image43 {
  width: 30px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
 
.home-test-text352 {
  color: var(--dl-color-gray-800);
  font-size: 1.6rem;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  text-shadow: 0 0 10px #000000;
  letter-spacing: -1px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text353 {
  color: var(--dl-color-key-mountainmist);
  z-index: 100;
  font-family: "Bai Jamjuree";
  font-weight: 400;
  line-height: 1.55;
  text-shadow: 0 0 10px #000000;
  text-transform: none;
  text-decoration: none;
}
 
.home-test-text359 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text362 {
  color: var(--dl-color-gray-800);
}
 
.home-test-text365 {
  color: var(--dl-color-gray-800);
}
 
.home-test-container89 {
  top: -25px;
  flex: 0 0 auto;
  left: calc(100% - 0px);
  width: 25px;
  bottom: -25px;
  margin: auto;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: flex-end;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
 
.home-test-image44 {
  width: 20px;
  object-fit: cover;
  margin-right: -15px;
}
 
.home-test-container90 {
  top: -25px;
  flex: 0 0 auto;
  right: calc(100% - 0px);
  width: 25px;
  bottom: -22px;
  margin: auto;
  display: none;
  z-index: 100;
  position: absolute;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius24);
  flex-direction: column;
  justify-content: center;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
 
.home-test-image45 {
  width: 20px;
  object-fit: cover;
  margin-left: -15px;
}
 
.home-test-img321 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: calc(100% + 48px);
  bottom: 0px;
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #000000;
  margin-top: -24px;
  align-items: flex-start;
  margin-left: -25px;
  margin-right: -25px;
  border-radius: var(--dl-radius-radius-radius24);
  margin-bottom: -24px;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(38, 43, 51, 0.9) 0.00%,rgba(38, 43, 51, 0.9) 99.00%),url("/photos/home/638f7ce82168a403dfdb20fc%20cropped.jpg");
  background-position: top left,center;
}
 
.home-test-bg7 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  display: none;
  opacity: 0.04;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-size: 900px;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(38, 43, 51, 0) 6.00%,rgba(38, 43, 51, 0) 89.00%,rgb(38, 43, 51) 100.00%),url("/photos/about/logo%20tiled%20special-1500w.png");
  background-position: center;
}
 
.home-test-meet-the-founder-page-cut {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-shrink: 0;
  margin-bottom: -5rem;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/photos/home/dave-robinson-b2kgtwnnbd4-unsplash%20edited1-1500w.jpg");
  background-position: top;
}
 
.home-test-gradient-container1 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: none;
  z-index: 1;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(44, 62, 80, 0) 1.00%,rgba(44, 62, 80, 0) 99.00%,rgb(38, 43, 51) 100.00%);
}
 
.home-test-blur-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(6px) contrast(80%);
}
 
.home-test-max-width6 {
  display: flex;
  max-width: 1000px;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row-reverse;
  padding-bottom: var(--dl-space-space-fiveunits);
}
 
.home-test-container91 {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  z-index: 101;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-test-heading-container8 {
  display: flex;
  align-self: center;
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
 
.home-test-prehead7 {
  color: var(--dl-color-key-blueribbon);
  font-size: 16px;
  font-family: "Goldman";
  font-weight: 400;
  text-shadow: 0 0 10px #fffa, 0 0 30px #fffa;
  margin-bottom: -4px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home-test-text368 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  margin-top: -6px;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 10px #fffa, 0 0 30px #fffa;
  letter-spacing: -1px;
}
 
.home-test-text372 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  display: none;
  text-align: center;
  line-height: 1;
  text-shadow: 0 0 30px #000000;
  letter-spacing: -1px;
}
 
.home-test-content {
  display: flex;
  align-self: center;
  flex-direction: row;
}
 
.home-test-line {
  flex: 0 0 auto;
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  border-color: var(--dl-color-key-blueribbon);
  border-style: solid;
  border-width: 2px;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: 5px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
 
.home-test-section-title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home-test-text375 {
  text-shadow: 0 0 10px #fffa;
}
 
.home-test-text378 {
  fill: var(--dl-color-gray-200);
  color: var(--dl-color-gray-200);
  text-shadow: 0 0 10px #fffa;
}
 
.home-test-image110 {
  bottom: 0px;
  height: 90%;
  z-index: 0;
  position: absolute;
  align-self: center;
  margin-right: 25rem;
}
 
.home-test-gradient-container2 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: none;
  z-index: 1;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(0deg, rgb(38, 43, 51) 0.00%,rgba(44, 62, 80, 0) 10.00%,rgba(44, 62, 80, 0) 90.00%,rgb(38, 43, 51) 100.00%);
}
 
.home-test-text384 {
  display: inline-block;
}
 
.home-test-text385 {
  display: inline-block;
}
 
.home-test-text386 {
  display: inline-block;
}
 
.home-test-text389 {
  display: inline-block;
}
 
.home-test-text390 {
  display: inline-block;
}
 
.home-test-text391 {
  display: inline-block;
}
 
.home-test-text394 {
  display: inline-block;
}
 
.home-test-text395 {
  display: inline-block;
}
 
.home-test-text396 {
  display: inline-block;
}
 
.home-test-text399 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .home-test-main-title {
    font-size: 3.2rem;
  }
  .home-test-text103 {
    text-align: left;
  }
  .home-test-text109 {
    max-width: 520px;
    text-align: left;
  }
  .home-test-bathroom-value {
    flex-direction: column;
  }
  .home-test-kitchen-value {
    flex-direction: column;
  }
  .home-test-text126 {
    font-size: 1.5rem;
  }
  .home-test-text127 {
    font-size: 1.5rem;
  }
  .home-test-text128 {
    font-size: 1.5rem;
  }
  .home-test-text132 {
    font-size: 1.5rem;
  }
  .home-test-project0 {
    justify-content: center;
  }
  .home-test-container21 {
    height: 250px;
  }
  .home-test-container27 {
    height: 250px;
  }
  .home-test-container33 {
    height: 250px;
  }
  .home-test-container38 {
    height: 250px;
  }
  .home-test-container44 {
    height: 250px;
  }
  .home-test-container49 {
    height: 250px;
  }
  .home-test-container56 {
    height: 250px;
  }
  .home-test-container62 {
    height: 250px;
  }
  .home-test-container68 {
    height: 250px;
  }
  .home-test-container73 {
    height: 250px;
  }
  .home-test-container79 {
    height: 250px;
  }
  .home-test-container84 {
    height: 250px;
  }
  .home-test-image110 {
    margin-right: 35%;
  }
}
 
@media(max-width: 767px) {
  .home-test-hero {
    justify-content: center;
  }
  .home-test-max-width1 {
    align-items: center;
  }
  .home-test-main-title {
    font-size: 42px;
  }
  .home-test-text103 {
    display: none;
    text-align: center;
  }
  .home-test-text109 {
    fill: var(--dl-color-gray-700);
    color: var(--dl-color-gray-700);
    display: block;
  }
  .home-test-photo-container1 {
    height: 400px;
  }
  .home-test-column1 {
    width: 80%;
  }
  .home-test-heading-container2 {
    align-items: center;
  }
  .home-test-text111 {
    font-size: 32px;
  }
  .home-test-text116 {
    font-size: 14px;
  }
  .home-test-photo-container2 {
    height: 400px;
  }
  .home-test-column2 {
    width: 80%;
  }
  .home-test-heading-container3 {
    align-items: center;
  }
  .home-test-text117 {
    font-size: 32px;
  }
  .home-test-text121 {
    font-size: 14px;
  }
  .home-test-heading-container4 {
    align-items: center;
  }
  .home-test-prehead3 {
    text-align: center;
  }
  .home-test-types {
    padding: var(--dl-space-space-unit);
  }
  .home-test-bathrooms {
    width: 48%;
  }
  .home-test-text126 {
    font-size: 1.8rem;
  }
  .home-test-kitchens {
    width: 48%;
  }
  .home-test-text127 {
    font-size: 1.8rem;
  }
  .home-test-renovations {
    width: 48%;
  }
  .home-test-text128 {
    font-size: 1.8rem;
  }
  .home-test-additions {
    width: 48%;
  }
  .home-test-text132 {
    font-size: 1.8rem;
  }
  .home-test-project-highlight {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-test-heading-container5 {
    align-items: center;
  }
  .home-test-prehead4 {
    text-align: center;
  }
  .home-test-project0 {
    flex-direction: column;
  }
  .home-test-photo {
    width: 100%;
  }
  .home-test-details {
    max-width: 100%;
    margin-left: 0;
  }
  .home-test-heading-container6 {
    align-items: center;
  }
  .home-test-prehead5 {
    text-align: center;
  }
  .home-test-text142 {
    font-size: 32px;
  }
  .home-test-text143 {
    display: none;
  }
  .home-test-text147 {
    color: var(--dl-color-gray-700);
    display: block;
  }
  .home-test-timeline-container1 {
    margin-right: var(--dl-space-space-tripleunit);
  }
  .home-test-step110 {
    align-items: flex-end;
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container21 {
    height: 100%;
  }
  .home-test-progress010 {
    height: 300px;
  }
  .home-test-container22 {
    bottom: 299px;
  }
  .home-test-text150 {
    bottom: 288px;
  }
  .home-test-container23 {
    top: 0;
    left: 40px;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-test-text153 {
    text-align: left;
  }
  .home-test-step111 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container27 {
    height: 100%;
  }
  .home-test-container28 {
    top: 0;
    left: 40px;
    right: auto;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-test-text170 {
    text-align: left;
  }
  .home-test-container31 {
    display: none;
  }
  .home-test-container32 {
    display: flex;
  }
  .home-test-step112 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container33 {
    height: 100%;
  }
  .home-test-container34 {
    top: 0;
    left: 40px;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-test-text186 {
    text-align: left;
  }
  .home-test-step113 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container38 {
    height: 100%;
  }
  .home-test-container39 {
    top: 0;
    left: 40px;
    right: auto;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-test-text202 {
    text-align: left;
  }
  .home-test-container42 {
    display: none;
  }
  .home-test-container43 {
    display: flex;
  }
  .home-test-step114 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container44 {
    height: 100%;
  }
  .home-test-container45 {
    top: 0;
    left: 40px;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-test-text218 {
    text-align: left;
  }
  .home-test-step115 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container49 {
    height: 100%;
  }
  .home-test-progress015 {
    height: 200px;
  }
  .home-test-text236 {
    top: 185px;
    left: auto;
    right: 10px;
  }
  .home-test-container51 {
    top: 0;
    left: 40px;
    right: auto;
    width: 70vw;
    position: relative;
  }
  .home-test-text239 {
    text-align: left;
  }
  .home-test-container54 {
    display: none;
  }
  .home-test-container55 {
    display: flex;
  }
  .home-test-heading-container7 {
    align-items: center;
  }
  .home-test-prehead6 {
    text-align: center;
  }
  .home-test-text255 {
    font-size: 32px;
  }
  .home-test-text256 {
    display: none;
  }
  .home-test-text260 {
    color: var(--dl-color-gray-700);
    display: block;
  }
  .home-test-timeline-container2 {
    margin-right: var(--dl-space-space-tripleunit);
  }
  .home-test-step116 {
    align-items: flex-end;
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container56 {
    height: 100%;
  }
  .home-test-progress016 {
    height: 300px;
  }
  .home-test-container57 {
    bottom: 299px;
  }
  .home-test-text263 {
    bottom: 288px;
  }
  .home-test-container58 {
    top: 0;
    left: 40px;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-test-text266 {
    text-align: left;
  }
  .home-test-step117 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container62 {
    height: 100%;
  }
  .home-test-container63 {
    top: 0;
    left: 40px;
    right: auto;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-test-text283 {
    text-align: left;
  }
  .home-test-container66 {
    display: none;
  }
  .home-test-container67 {
    display: flex;
  }
  .home-test-step118 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container68 {
    height: 100%;
  }
  .home-test-container69 {
    top: 0;
    left: 40px;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-test-text299 {
    text-align: left;
  }
  .home-test-step119 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container73 {
    height: 100%;
  }
  .home-test-container74 {
    top: 0;
    left: 40px;
    right: auto;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-test-text315 {
    text-align: left;
  }
  .home-test-container77 {
    display: none;
  }
  .home-test-container78 {
    display: flex;
  }
  .home-test-step120 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container79 {
    height: 100%;
  }
  .home-test-container80 {
    top: 0;
    left: 40px;
    width: 70vw;
    position: relative;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-test-text331 {
    text-align: left;
  }
  .home-test-step121 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-test-container84 {
    height: 100%;
  }
  .home-test-progress021 {
    height: 200px;
  }
  .home-test-text349 {
    top: 185px;
    left: auto;
    right: 10px;
  }
  .home-test-container86 {
    top: 0;
    left: 40px;
    right: auto;
    width: 70vw;
    position: relative;
  }
  .home-test-text352 {
    text-align: left;
  }
  .home-test-container89 {
    display: none;
  }
  .home-test-container90 {
    display: flex;
  }
  .home-test-max-width6 {
    gap: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .home-test-container91 {
    width: 100%;
    margin-bottom: 75%;
  }
  .home-test-heading-container8 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-test-prehead7 {
    text-align: center;
  }
  .home-test-content {
    width: 80%;
  }
  .home-test-image110 {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
}
 
@media(max-width: 479px) {
  .home-test-max-width2 {
    gap: 24px;
  }
  .home-test-column1 {
    width: 100%;
  }
  .home-test-text111 {
    font-size: 26px;
  }
  .home-test-column2 {
    width: 100%;
  }
  .home-test-text117 {
    font-size: 26px;
  }
  .home-test-text122 {
    font-size: 2rem;
  }
  .home-test-types {
    align-items: center;
    flex-direction: column;
  }
  .home-test-text136 {
    font-size: 2rem;
  }
  .home-test-step110 {
    margin-left: 0;
  }
  .home-test-text150 {
    display: none;
  }
  .home-test-step111 {
    margin-left: 0;
  }
  .home-test-step112 {
    margin-left: 0;
  }
  .home-test-step113 {
    margin-left: 0;
  }
  .home-test-step114 {
    margin-left: 0;
  }
  .home-test-step115 {
    margin-left: 0;
  }
  .home-test-text236 {
    display: none;
  }
  .home-test-step116 {
    margin-left: 0;
  }
  .home-test-text263 {
    display: none;
  }
  .home-test-step117 {
    margin-left: 0;
  }
  .home-test-step118 {
    margin-left: 0;
  }
  .home-test-step119 {
    margin-left: 0;
  }
  .home-test-step120 {
    margin-left: 0;
  }
  .home-test-step121 {
    margin-left: 0;
  }
  .home-test-text349 {
    display: none;
  }
  .home-test-max-width6 {
    gap: 24px;
  }
  .home-test-text368 {
    display: none;
  }
  .home-test-text372 {
    display: block;
    font-size: 2rem;
  }
  .home-test-text387 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-test-text397 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
